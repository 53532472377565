import React from "react";

const FilterPriceRate = ({
  t,
  Start,
  togglePriceMenu,
  openPriceMenu,
  setOpenPriceMenu,
  servicesProvider,
  subServiceID,
  toggleRateMenu,
  openRateMenu,
  setOpenRateMenu,
  PriceIcon,
}) => {
  return (
    <div className="flex flex-col h-full px-3 pt-2 lg:gap-2 montserrat ">
      <div className="relative">
        <button
          onClick={togglePriceMenu}
          className="flex flex-row items-center justify-between gap-1 text-[#576E82]   text-[12px] font-[600]"
        >
          <img src={PriceIcon} className="w-[8px] h-[15px]" alt="mibo" />
          <span>{t("Price")}</span>
        </button>

        <div className=" text-[12px] text-primary-color items-start   flex flex-col font-[400]  lg:gap-1 lg:pt-1 pl-3 ">
          <button
            onClick={() => {
              setOpenPriceMenu(false);
              servicesProvider(subServiceID, {
                sort: "price",
                dir: "max",
              });
            }}
            className="flex items-start w-full p-1 rounded-lg hover:bg-third-color"
          >
            {t("Highest to Lowest")}
          </button>
          <button
            onClick={() => {
              setOpenPriceMenu(false);
              servicesProvider(subServiceID, {
                sort: "price",
                dir: "min",
              });
            }}
            className="flex items-start w-full p-1 rounded-lg hover:bg-third-color"
          >
            {t("Lowest to Highest")}
          </button>
        </div>
      </div>
      <div className="relative ">
        <button
          onClick={toggleRateMenu}
          className="flex flex-row items-center text-[#576E82] justify-between gap-1   text-[12px] font-[600]"
        >
          <img src={Start} className="w-[15px] h-[15px]" alt="mibo" />
          <span>{t("Rate")}</span>
        </button>

        <div className=" text-[12px] text-primary-color items-start flex flex-col font-[400]  lg:gap-1 lg:pt-1 pl-3 ">
          <button
            onClick={() => {
              setOpenRateMenu(false);
              servicesProvider(subServiceID, {
                sort: "rate",
                dir: "max",
              });
            }}
            className="flex items-start w-full p-1 rounded-lg hover:bg-third-color"
          >
            {t("Highest to Lowest")}
          </button>
          <button
            onClick={() => {
              setOpenRateMenu(false);
              servicesProvider(subServiceID, {
                sort: "rate",
                dir: "min",
              });
            }}
            className="flex items-start w-full p-1 rounded-lg hover:bg-third-color"
          >
            {t("Lowest to Highest")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPriceRate;
