import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const BookingMap = ({ lat, long, setOpenMap }) => {
  const mapRef = useRef(null);
  const popupRef = useRef(null);
  const defaultPosition = { lat: parseFloat(lat), lng: parseFloat(long) };

  const handleClickOutside = useCallback(
    (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setOpenMap(false);
      }
    },
    [setOpenMap]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        setOpenMap(false);
      }
    },
    [setOpenMap]
  );

  useEffect(() => {
    document?.addEventListener("mousedown", handleClickOutside);
    document?.addEventListener("keydown", handleKeyDown);

    return () => {
      document?.removeEventListener("mousedown", handleClickOutside);
      document?.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClickOutside, handleKeyDown]);
  const { t } = useTranslation("index");
  return (
    <div className="fixed inset-0 w-screen h-screen bg-black/75 z-[999] flex justify-center items-center">
      <div className="flex items-center justify-center w-11/12 lg:w-2/3 aspect-square lg:aspect-video">
        <div
          ref={popupRef}
          className="w-full h-full text-white rounded-md lg:w-full "
        >
          <p className="my-2 text-xl lg:my-10">{t("location")}</p>
          <LoadScript
            googleMapsApiKey="AIzaSyDKR1ZaGk-Is5GKCln82RM6375QlQwnUa4"
            libraries={["places"]}
          >
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "500px",
                borderRadius: "20px",
              }}
              mapContainerClassName="map"
              zoom={8}
              center={defaultPosition}
              onLoad={(map) => (mapRef.current = map)}
              options={{
                zoomControl: true,
                streetViewControl: true,
                mapTypeControl: false,
                fullscreenControl: true,
                draggableCursor: true,
              }}
            >
              <MarkerF position={defaultPosition} title={t("Your Location")} />
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </div>
  );
};

export default BookingMap;
