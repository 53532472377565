import {
  DirectionsRenderer,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import pusherClient from "pusher";
import { useCallback, useEffect, useRef, useState } from "react";
import { trackingMap } from "store/trackingMap";

const TrackingMap = ({ setOpen }) => {
  const booking = trackingMap((state) => state.booking);
  const setBooking = trackingMap((state) => state.setBooking);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDKR1ZaGk-Is5GKCln82RM6375QlQwnUa4",
  });

  const popupRef = useRef(null);

  useEffect(() => {
    const handleStartTracking = (serviceProviderLocation) => {
      const [lat, long] = serviceProviderLocation.split(",");
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: {
            lat: parseFloat(booking?.lat),
            lng: parseFloat(booking?.long),
          },
          destination: {
            lat: parseFloat(lat),
            lng: parseFloat(long),
          },
          travelMode: "DRIVING",
        },
        directionsCallback
      );
    };

    let channel = pusherClient.channel(
      `private-booking.${booking.id}.tracking`
    );
    if (!channel) {
      channel = pusherClient.subscribe(
        `private-booking.${booking.id}.tracking`
      );
    }

    channel.unbind("client-my_event", handleStartTracking);
    channel.bind("client-my_event", handleStartTracking);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.id]);

  const [directions, setDirections] = useState(null);

  const directionsCallback = (response) => {
    if (response !== null) {
      if (response.status === "OK") {
        setDirections(response);
      }
    }
  };

  const handleClickOutside = useCallback(
    (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setOpen(false);
        setBooking(undefined);
      }
    },
    [setOpen, setBooking]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        setOpen(false);
        setBooking(undefined);
      }
    },
    [setBooking, setOpen]
  );

  useEffect(() => {
    document?.addEventListener("mousedown", handleClickOutside);
    document?.addEventListener("keydown", handleKeyDown);

    return () => {
      document?.removeEventListener("mousedown", handleClickOutside);
      document?.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClickOutside, handleKeyDown]);

  if (!isLoaded) return <p>loading..</p>;
  return (
    <div className="fixed inset-0 w-screen h-screen bg-black/75 z-[999] flex justify-center items-center">
      <div
        ref={popupRef}
        className="flex items-center justify-center w-11/12 lg:w-2/3 aspect-square lg:aspect-video"
      >
        <div className="w-full h-full text-white rounded-md lg:w-full ">
          <GoogleMap
            mapContainerClassName="w-full h-full rounded-xl map"
            zoom={10}
            center={{
              lat: parseFloat(booking.lat),
              lng: parseFloat(booking.long),
            }}
            options={{
              zoomControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
            }}
          >
            <MarkerF
              position={{
                lat: parseFloat(booking.lat),
                lng: parseFloat(booking.long),
              }}
            />
            {directions && (
              <DirectionsRenderer
                options={{
                  directions: directions,
                }}
              />
            )}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
};

export default TrackingMap;
