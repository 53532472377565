const NotificationCard = ({
  notification,
  handelNavigateButton,
  formatDate,
}) => {
  return (
    <div
      onClick={() => handelNavigateButton(notification)}
      className={`flex justify-between lg:w-full gap-2 lg:ml-5 group rounded-lg hover:bg-black/10 p-2 text-center duration-500 cursor-pointer lg:px-5 ${
        !notification.is_read ? "bg-black/20" : ""
      }`}
    >
      <div className="flex gap-2 lg:gap-10 items-start">
        <div
          className={`w-12 h-12 lg:h-12 overflow-hidden lg:w-12 rounded-full`}
        >
          <img
            src={
              notification?.provider_image
                ? notification?.provider_image
                : "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
            }
            alt=""
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex flex-col gap-2 text-start">
          <p className="text-sm lg:text-xl">{notification.title}</p>
          <p className="text-sm">{notification.description}</p>
          <p className="text-xs Roboto text-[#92929D]">
            {formatDate(notification.updated_at)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
