import img2 from "assets/images/Auth/ImgRegister.png";
import img3 from "assets/images/Auth/OO.jpg";
import img from "assets/images/Auth/sss.jpg";
import hiddenPassIcon from "assets/images/hiddenPass.png";
import showPassIcon from "assets/images/showPass.png";
import Loader from "components/styled/Loader";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Register from "../Register/Register";
import LoginWithApple from "./components/LoginWithApple";
import LoginWithFacebook from "./components/LoginWithFacebook";
import LoginWithGoogle from "./components/LoginWithGoogle";
import { useSignInLogic } from "./hooks/useSignInLogic";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import PrivacyPolicyDialog from "components/Dialog/PrivacyPolicyDialog";
import TermsOfUsDialog from "components/Dialog/TermsOfUsDialog";
import { Autoplay, Pagination } from "swiper/modules";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";

const Singin = ({ setOpenSignIn, setOpenSignUp, openSignUp }) => {
  const {
    t,
    loading,
    showPass,
    navigate,
    onSubmit,
    handleClose,
    handleSubmit,
    hanldeShowPass,
    register,
    errors,
    googleLoading,
    control,
    setopenPricavyDialog,
    openPricavyDialog,
    termsOfUs,
    setOpenTerms,
  } = useSignInLogic({ setOpenSignIn });

  const [isAppleDevice] = useState(() =>
    /iPad|iPhone|iPod/.test(navigator.userAgent)
  );
  return (
    <>
      {openPricavyDialog ? (
        <PrivacyPolicyDialog
          setopenPricavyDialog={setopenPricavyDialog}
          openPricavyDialog={openPricavyDialog}
        />
      ) : termsOfUs ? (
        <TermsOfUsDialog setOpenTerms={setOpenTerms} termsOfUs={termsOfUs} />
      ) : (
        <div className="">
          {googleLoading || loading ? (
            <div className="fixed top-0 z-10 w-screen h-screen">
              <Loader />
            </div>
          ) : (
            <div className="fixed top-0 z-50 flex items-center justify-center w-full h-full">
              <div className="relative flex items-center justify-center w-full h-full">
                <div className="relative z-10 flex items-center justify-center w-full h-full">
                  <div
                    className="w-full h-full !cursor-pointer absolute z-0"
                    onClick={handleClose}
                  ></div>
                  <div
                    className={`text-white hidden lg:flex overflow-hidden lg:w-[290px] rounded-l-2xl relative z-10 `}
                  >
                    <Swiper
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Autoplay, Pagination]}
                      className="overflow-hidden mySwiper"
                    >
                      <SwiperSlide>
                        <img
                          src={img}
                          className="w-full object-cover max-h-[600px] lg:max-h-[700px]"
                          alt=""
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={img2}
                          className="w-full object-cover max-h-[600px] lg:max-h-[700px]"
                          alt=""
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={img3}
                          className="w-full object-cover max-h-[600px] lg:max-h-[700px]"
                          alt=""
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div
                    className={`lg:rounded-r-xl max-h-[600px] lg:max-h-[700px] relative z-10 flex flex-col rounded-2xl lg:rounded-none overflow-auto scrollbar-hide bg-white w-full lg:w-[575px] p-3 lg:p-5 max-lg:m-3 lg:m-0`}
                  >
                    <div className="flex justify-end w-full">
                      <p
                        className="p-2 font-bold text-[18px] cursor-pointer"
                        onClick={handleClose}
                      >
                        X
                      </p>
                    </div>

                    <div className="flex flex-col items-center justify-center">
                      <p className="text-[24px] font-bold">Mibo</p>
                      <p className="text-[27px] max-lg:text-[22px] lg:font-semibold mt-2">
                        {t("Login")}
                      </p>
                    </div>
                    <div className="flex justify-center w-full">
                      <p className="flex gap-1 text-sm">
                        {t("Don't have an account?")}
                        <span
                          onClick={() => {
                            setOpenSignUp(true);
                            setOpenSignIn(false);
                          }}
                          className="font-bold cursor-pointer text-primary-color"
                        >
                          {t("Signup")}
                        </span>
                      </p>
                      {openSignUp && <Register />}
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="flex flex-col gap-[17px] 2xl:gap-[20px] max-h-[500px] mt-4 lg:max-h-max  lg:px-10"
                    >
                      <div className="flex flex-col gap-2">
                        <label className="text-[#666666] !font-[400] md:text-[13.59px]">
                          {t("Phone")}
                        </label>
                        <Controller
                          control={control}
                          name="phone"
                          rules={{ required: true }}
                          render={({ field: { ref, ...field } }) => (
                            <PhoneInput
                              {...field}
                              inputExtraProps={{
                                ref,
                                required: true,
                                autoFocus: true,
                              }}
                              country={"de"}
                              regions={"europe"}
                              inputClass="!w-full !h-fit !py-2.5 border border-primary-color max-md:!text-[12px] !rounded-xl md:!rounded-lg"
                            />
                          )}
                        />
                        {errors.phone && (
                          <span className="text-red-500">
                            {errors.phone.message}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className=" text-[#666666] relative text-[13.5px]">
                          {t("Password")}
                        </label>
                        <div className="relative">
                          <input
                            placeholder={t("Enter Password")}
                            type={showPass ? "text" : "password"}
                            id="password"
                            name="password"
                            {...register("password")}
                            className="w-full p-2.5 max-lg:text-[12px] rounded-xl  md:p-3 border border-primary-color md:rounded-lg"
                          />
                          <img
                            src={showPass ? showPassIcon : hiddenPassIcon}
                            className={`absolute w-5 text-black cursor-pointer right-2   top-1/2 transform -translate-y-1/2`}
                            alt=""
                            onClick={hanldeShowPass}
                          />
                        </div>
                        {errors.password && (
                          <span className="text-red-500">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                      <div className="flex justify-between ">
                        <div className="flex gap-2">
                          <input
                            type="checkbox"
                            name="remember"
                            id="remember"
                          />
                          <label
                            htmlFor="remember"
                            className="cursor-pointer text-[13px]"
                          >
                            {t("Remember me")}
                          </label>
                        </div>
                        <div>
                          <p
                            className="cursor-pointer text-[13px] hover:font-semibold "
                            onClick={() => {
                              navigate("/reset-password");
                              setOpenSignIn(false);
                            }}
                          >
                            {t("Forgot password")}?
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center w-full">
                        <button
                          type="submit"
                          className="relative w-full h-12 text-white rounded-3xl bg-primary-color"
                          disabled={loading}
                        >
                          <div className="absolute transform text-[17px] py-[14px] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                            {t("Log in")}
                          </div>
                        </button>

                        {/* <div className="py-4 text-center md:px-20 md:py-5">
                      <p>
                        {t("Protected by X A and subject to the Prism")}
                        <span className="text-primary-color">
                          {t("Privacy Policy")}
                        </span>
                        {t("and")}
                        <span className="text-primary-color">
                          {t("Terms of Service.")}
                        </span>
                      </p>
                    </div> */}
                      </div>
                      <div className="flex flex-wrap items-center justify-center w-full text-center sm:gap-2 ">
                        {googleLoading ? (
                          <p>{t("loading")}</p>
                        ) : (
                          <div className="flex flex-col items-center justify-center !w-full gap-2">
                            <LoginWithGoogle handleClose={handleClose} />
                            <LoginWithFacebook handleClose={handleClose} />
                            {isAppleDevice ? (
                              <LoginWithApple handleClose={handleClose} />
                            ) : null}
                          </div>
                        )}
                      </div>
                    </form>
                    <div className="flex items-center max-lg:text-[12px] gap-1 justify-center mt-3">
                      <div className="text-[#666666]">
                        {t("By continuing, you agree to the")}
                      </div>
                      <p
                        onClick={() => {
                          setOpenTerms(true);
                        }}
                        className="font-[400] underline cursor-pointer"
                      >
                        {" "}
                        {t("Terms of Service")}
                      </p>
                    </div>
                    <div className="flex items-center gap-1 max-lg:text-[12px] justify-center mb-3">
                      <div className="text-[#666666]">
                        {t("and acknowledge you’ve read our")}
                      </div>
                      <p
                        onClick={() => {
                          setopenPricavyDialog(true);
                        }}
                        className="cursor-pointer font-[400] underline"
                      >
                        {" "}
                        {t("Privacy Policy.")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default Singin;
