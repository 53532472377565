import React from "react";
import SideBar from "./SideBar";
import SideBarResponsive from "./SideBarResponsive";

const SideBarLayout = ({ children }) => {
  return (
    <div className="flex flex-col md:flex md:flex-row">
      <div className="hidden md:block">
        <SideBar />
      </div>
      <div className="block md:hidden">
        <SideBarResponsive />
      </div>
      <div className="w-1/2">{children}</div>
    </div>
  );
};

export default SideBarLayout;
