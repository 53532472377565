import * as yup from "yup";

export const useValidation = () => {
  const schema = yup.object().shape({
    phone: yup
      .string()
      .required("phone is required")
      .min(10, "phone number is not valid"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "The Password must be of six characters")
      .max(20, "The Password must be of 20 characters"),
  });
  return {
    schema,
  };
};
