import CompleteProject from "assets/images/profile/Complet Projects.svg";
import RateIcon from "assets/images/profile/Rate.svg";
import WorkExperianceIcon from "assets/images/profile/Work Experiance.svg";
import { _axios } from "interceptor/http-config";
import perhourIcon from "assets/images/profile/perhour.svg";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { servicesStore } from "store/servicesStore";
import Swal from "sweetalert2";
export const useBookingShow = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("account");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [addCoupon, setAddCoupon] = useState(false);
  const [code, setCode] = useState("");
  const [couponLoading, setCouponLoading] = useState(false);
  const [servicesName, subServicesName, serviceProvider] = servicesStore(
    (state) => [
      state.serviceProvider,
      state.servicesName,
      state.subServicesName,
    ]
  );

  // Get all query parameters as an array
  const [favoriteStatus, setFavoriteStatus] = useState({});
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const queryClient = useQueryClient();
  const params = useParams();
  const [ServiceProvID, setServiceProvID] = useState("");
  const favoriteToggle = useMutation(
    async () => {
      // Send the update to the server
      return _axios
        .get(`user/toggle-favourite/${ServiceProvID}`)
        .then((response) =>
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          })
        );
    },
    {
      onMutate: async (id) => {
        // Optimistically update the favoriteStatus
        // const updatedFavoriteStatus = { ...favoriteStatus };
        // updatedFavoriteStatus[id] = !updatedFavoriteStatus[id];
        // setFavoriteStatus(updatedFavoriteStatus);

        await queryClient.cancelMutations(""); // Cancel any pending mutations for this key
        return id; // Pass the id as context for the onSettled function
      },
      onError: (error, variables, context) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            alert(`${field}: ${errorMessages.join(", ")}`);
            window.location.reload();
          });
        }
      },
      onSettled: (id, error, result) => {
        // Handle success or error cases and clear loading state
        if (!error) {
          queryClient.invalidateQueries(["favourite"]);
        } else {
          // If there's an error, revert the optimistic update
          const updatedFavoriteStatus = { ...favoriteStatus };
          updatedFavoriteStatus[id] = !updatedFavoriteStatus[id];
          setFavoriteStatus(updatedFavoriteStatus);
        }
        setLoadingFavorite(false);
      },
      onSuccess: (response) => {},
    }
  );

  const handleFavorite = async (id) => {
    setLoadingFavorite(true);
    try {
      await favoriteToggle.mutateAsync(id);
      refetch();
    } catch (error) {
      console.error("Async mutation error:", error);
    }
  };

  const {
    data: bookingShow,
    isLoading,
    refetch,
  } = useQuery(
    ["booking-show", `id-${params.id}`],
    async () => {
      return _axios.get(`/user/booking/${params.id}`).then((res) => {
        setServiceProvID(res.data?.booking?.service_provider?.id);
        return res.data;
      });
    },
    {}
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const details = [
    {
      icon: WorkExperianceIcon,
      data: bookingShow?.booking.service_provider.work_experience,
      text: t("Work Experience"),
    },
    {
      icon: CompleteProject,
      data: bookingShow?.booking.service_provider.completed_project,
      text: t("Completed Projects"),
    },
    {
      icon: RateIcon,
      data: bookingShow?.booking.service_provider.rate
        ? bookingShow?.booking.service_provider.rate
        : 0,
      text: t("User Review"),
    },
    {
      icon: perhourIcon,
      data: bookingShow?.booking.price,
      text: t("Per Hour"),
    },
  ];

  const lat = bookingShow?.booking?.lat;
  const long = bookingShow?.booking?.long;

  const cancel = useMutation(
    async (id) => {
      const formData = new FormData();
      formData.append("status", "cancel");
      return _axios
        .post(`user/booking/change-status/${id}`, formData)
        .then((response) => {
          Swal.fire(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
          navigate("/account");
        });
    },
    {
      onError: (error, variables, context) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            Swal.fire(`${field}: ${errorMessages.join(", ")}`);
          });
        }
      },
      onSettled: () => {
        // This is called after the mutation is complete (whether it succeeded or failed)
        queryClient.invalidateQueries(["booking"]);
        setCancelLoading(false);
      },
    }
  );
  const handleCancel = (id) => {
    setCancelLoading(true);
    cancel.mutate(id);
  };
  const handlePayment = () => {
    setPaymentLoading(true);
    _axios
      .get(`/user/booking/payment/${params.id}`)
      .then((res) => {
        const url = res.data.url;
        setPaymentLoading(true);
        if (url) {
          window.location.href = url;
        } else {
          enqueueSnackbar("URL Invalid", {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
        }
      })
      .finally(() => setPaymentLoading(false));
  };
  const handleAddCouponMutation = useMutation(
    async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("code", code);
      setCouponLoading(true);
      return _axios
        .post(`/user/coupon/booking/${params.id}`, formData)
        .then((response) => {
          Swal.fire(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
        })
        .finally(() => {
          setAddCoupon(false);
          setCouponLoading(false);
        });
    },

    {
      onError: (error, variables, context) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          if (typeof message === "string") {
            // If message is a string, display it directly
            Swal.fire(message, "error");
          } else if (typeof message === "object") {
            // If message is an object, iterate through its fields and display error messages
            Object.keys(message).forEach((field) => {
              const errorMessages = message[field];
              Swal.fire(`${field}: ${errorMessages.join(", ")}`, "error");
            });
          }
        } else {
          // Handle other types of errors or display a generic error message
          Swal.fire("An error occurred", "error");
        }
        setCouponLoading(false);
      },
      onSettled: () => {
        // This is called after the mutation is complete (whether it succeeded or failed)
        queryClient.invalidateQueries(["booking"]);
        setCouponLoading(false);
      },
      onSuccess: () => {
        refetch();
      },
    }
  );
  const handleAddCoupon = (id) => {
    handleAddCouponMutation.mutate(id);
  };
  return {
    paymentLoading,
    setPaymentLoading,
    openRating,
    setOpenRating,
    openReport,
    setOpenReport,
    openMap,
    setOpenMap,
    cancelLoading,
    servicesName,
    subServicesName,
    serviceProvider,
    loadingFavorite,
    handleFavorite,
    isLoading,
    details,
    lat,
    long,
    handleCancel,
    handlePayment,
    bookingShow,
    navigate,
    t,
    handleAddCoupon,
    params,
    code,
    addCoupon,
    setAddCoupon,
    couponLoading,
    setCode,
  };
};
