import Loader from "components/styled/Loader";
import { _axios } from "interceptor/http-config";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

const ClearChat = ({ setChatActions, username, chatID, setClearChat }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation("chat");
  const [clearLoading, setClearLoading] = useState(false);
  const clearToggle = useMutation(
    async () => {
      return _axios.delete(`/user/conversation/${chatID}`).then((response) =>
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
    },
    {
      onMutate: async (id) => {
        await queryClient.cancelMutations("");
        return id;
      },
      onError: (error, variables, context) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            enqueueSnackbar(errorMessages.join(", "), {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
          });
        }
      },
      onSettled: (id, error, result) => {
        if (!error) {
          queryClient.invalidateQueries(["chat"]);
        }
        setClearLoading(false);
        setChatActions(false);
      },
      onSuccess: (response) => {
        setChatActions(false);
        setClearChat(true);
      },
    }
  );

  const handleClear = async (id) => {
    setClearLoading(true);
    try {
      await clearToggle.mutateAsync(id);
    } catch (error) {
      console.error("Async mutation error:", error);
    }
  };
  return (
    <>
      {clearLoading && <Loader />}
      <div
        onClick={() => setChatActions(false)}
        className="fixed top-0 left-0 z-10 flex items-center justify-center w-screen h-screen bg-black/50"
      ></div>
      <div className="fixed top-[25%] lg:p-10 flex flex-col lg:justify-around gap-20 lg:gap-0 justify-center z-50 bg-white h-60  p-1 rounded-lg lg:left-1/3 left-0 mx-10 lg:mx-0 lg:w-96 ">
        <p className="text-2xl text-[#213241] text-center">
          {t(`Are you sure you want to Clear Chat Of`)} {username} ?
        </p>
        <div className="flex justify-center w-full gap-10 text-white">
          <button
            className="py-2 px-14 bg-primary-color rounded-xl"
            onClick={handleClear}
          >
            {t("Yes")}
          </button>
          <button
            className="py-2 border px-14 border-primary-color rounded-xl text-primary-color"
            onClick={() => setChatActions(false)}
          >
            {t("No")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ClearChat;
