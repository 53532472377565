import blockIcon from "assets/icons/chat/blockIcon.svg";
import chatIcon from "assets/icons/chat/chatIcon.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const Modules = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("chat");
  const [active, setActive] = useState(null);

  const [isHovered, setIsHovered] = useState({
    message: false,
    block: false,
    setting: false,
  });

  const handleMouseEnter = (component) => {
    setIsHovered({ ...isHovered, [component]: true });
  };
  const handleMouseLeave = (component) => {
    setIsHovered({ ...isHovered, [component]: false });
  };
  const handleActive = (component) => {
    setActive(component === active ? null : component);
    setIsHovered(false);
  };
  return (
    <div className="lg:flex lg:flex-col hidden h-[1110px] gap-10 bg-primary-color ">
      <div className="w-full mt-40 ">
        <div
          onClick={() => {
            navigate("/chat/message");
            handleActive("message");
          }}
          className={`w-full px-10 cursor-pointer py-3 my-5 ${
            active === "message" ? "bg-[#465472]" : ""
          } ${location.pathname === "/chat/message" && "bg-[#465472]"}`}
        >
          <img
            src={chatIcon}
            alt=""
            onMouseEnter={() => handleMouseEnter("message")}
            onMouseLeave={() => handleMouseLeave("message")}
          />
          {isHovered.message && (
            <p className="absolute inline-block p-2 text-xs text-white transition-opacity duration-300 rounded-full shadow-sm bg-black/60 ">
              {t("messages")}
            </p>
          )}
        </div>
        <div
          onClick={() => {
            navigate("/chat/block");
            handleActive("block");
          }}
          className={`w-full px-10 cursor-pointer py-3 my-5 ${
            active === "block" ? "bg-[#465472]" : ""
          }
          ${location.pathname === "/chat/block" && "bg-[#465472]"}`}
        >
          <img
            src={blockIcon}
            alt=""
            onMouseEnter={() => handleMouseEnter("block")}
            onMouseLeave={() => handleMouseLeave("block")}
          />
          {isHovered.block && (
            <p className="absolute inline-block p-2 text-xs text-white transition-opacity duration-300 rounded-full shadow-sm bg-black/60 ">
              {t("Blocked")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modules;
