import { _AuthApi } from "api/auth";
import searchIcon from "assets/icons/search.png";
import phoneImg from "assets/images/home/22.png";
import useSearch from "components/search/useSearch";
import Loader from "components/styled/Loader";
import { useMostAsked } from "hooks/home/useMostAsked";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "swiper/css/effect-fade";
import ArrowLeft from "assets/icons/ArrowLeft.svg";
import Map from "components/Map/Map";
import locationHome from "assets/icons/home/locationHome.svg";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { _axios } from "interceptor/http-config";
import Swal from "sweetalert2";
import "swiper/css/effect-fade";
import locationOrder from "assets/icons/locationOrder.svg";
import { useLocationStore } from "store/useLocationStore";
import Search from "components/search/Search";

const Hero = () => {
  const access_token = _AuthApi.getToken();
  const homeRef = useRef();
  const { t } = useTranslation("index");
  const [name, setName] = useState("");
  const [openMap, setOpenMap] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationData, setLocationData] = useState();
  const [locationName, setLocationName] = useState("");
  const [locationID, setLocationID] = useLocationStore((state) => [
    state.locationID,
    state.setLocationID,
  ]);

  useEffect(() => {
    access_token &&
      _axios
        .get("/user/location", {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => setLocationData(res.data));
  }, [access_token]);

  const handleSelect = (e) => {
    e.preventDefault();

    if (!selectedLocation) {
      // Display error message if selectedLocation is empty
      Swal.fire("Error", "Location is required", "error");
      return; // Exit function if selectedLocation is empty
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("lat", selectedLocation.lat);
    formData.append("long", selectedLocation.lng);
    setLoading(true);

    _axios
      .post("/user/location", formData)
      .then(() => setLoading(true))
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            Swal.fire(`${field}: ${errorMessages.join(", ")}`);
          });
        } else {
          Swal.fire(`Network connection...`);
        }
      })
      .finally(() => {
        setLoading(false);
        setOpenMap(false);
        setOpenLocation(false);
      });
  };

  const {
    handleChange,
    inputText,
    setInputText,
    setOpenSearchModal,
    openSearchModal,
    mutate,
  } = useSearch();

  const handleSearchInputClick = () => {
    if (homeRef.current && homeRef.current.swiper) {
      homeRef.current.swiper.autoplay.stop();
    }
  };
  const [isAppleDevice] = useState(
    /(iPhone|iPod|iPad)/.test(navigator.platform)
  );

  const getAppLink = () => {
    if (isAppleDevice) {
      window.location.href =
        "https://apps.apple.com/ae/app/mibo-home-services/id6472888774";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.rlink.mibo_user";
    }
  };
  const { data, isLoading } = useMostAsked();
  const navigate = useNavigate();
  const handleViewSubServices = (id) => {
    navigate(`services/view/${id}`);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col lg:flex-row lg:flex h-[750px] gap-0 lg:gap-0 mb-4">
          <div className="flex flex-col justify-center h-full gap-4 2xl:flex lg:w-1/2 px-6 2xl:!px-30 montserrat lg:mt-10 lg:pl-40 mt-8">
            <div className="flex flex-col leading-[40px] lg:leading-[80px] ">
              <div className="flex flex-col font-semibold text-[38.85px] lg:text-[35px] 2xl:text-[60px]">
                <p className="flex">
                  {t("home.Your")}
                  <span className="h-14 lg:h-28 pl-1 text-7xl lg:!text-[130px] Surpass-Holiday text-fifth-color  font-thin   ">
                    {t("home.Homes")}
                  </span>
                </p>
              </div>
              <p className="flex flex-col font-semibold text-[38.85px] lg:text-[35px] 2xl:text-[60px] !leading-[48.58px] lg:leading-[62.74px]">
                {t("home.One-Stop")} <br /> {t("home.hero squad!")}
              </p>
            </div>
            {access_token ? (
              <button
                onClick={() => setOpenLocation(true)}
                className="relative w-10/12 lg:w-[400px]"
              >
                <div className="!text-xs relative font-medium placeholder:text-[#b8b7b7] border !border-[#6F6F6F] !rounded-[50px] w-full h-12 px-4 lg:h-full !py-4 text lg:text-lg">
                  <div className="absolute flex items-center justify-center w-8 h-8 rounded-full top-2 left-2 lg:top-2 lg:left-2">
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0.832031C9.98912 0.832031 11.8968 1.62221 13.3033 3.02873C14.7098 4.43525 15.5 6.34291 15.5 8.33203C15.5 10.8937 14.1033 12.9904 12.6317 14.4945C11.8963 15.2378 11.094 15.9117 10.235 16.5079L9.88 16.7495L9.71333 16.8604L9.39917 17.0604L9.11917 17.2312L8.7725 17.4329C8.53709 17.5669 8.27087 17.6373 8 17.6373C7.72913 17.6373 7.46291 17.5669 7.2275 17.4329L6.88083 17.2312L6.4475 16.9645L6.2875 16.8604L5.94583 16.6329C5.01915 16.0056 4.15589 15.2895 3.36833 14.4945C1.89667 12.9895 0.5 10.8937 0.5 8.33203C0.5 6.34291 1.29018 4.43525 2.6967 3.02873C4.10322 1.62221 6.01088 0.832031 8 0.832031ZM8 5.83203C7.6717 5.83203 7.34661 5.8967 7.04329 6.02233C6.73998 6.14797 6.46438 6.33212 6.23223 6.56426C6.00009 6.79641 5.81594 7.07201 5.6903 7.37532C5.56466 7.67864 5.5 8.00373 5.5 8.33203C5.5 8.66034 5.56466 8.98543 5.6903 9.28874C5.81594 9.59205 6.00009 9.86765 6.23223 10.0998C6.46438 10.3319 6.73998 10.5161 7.04329 10.6417C7.34661 10.7674 7.6717 10.832 8 10.832C8.66304 10.832 9.29893 10.5686 9.76777 10.0998C10.2366 9.63096 10.5 8.99507 10.5 8.33203C10.5 7.66899 10.2366 7.03311 9.76777 6.56426C9.29893 6.09542 8.66304 5.83203 8 5.83203Z"
                        fill="#FF2626"
                      />
                    </svg>
                  </div>
                  <p className="ps-6 capitalize text-start">
                    {locationID
                      ? locationData?.locations.find(
                          (location) => location.id.toString() === locationID
                        )?.name
                      : t("your location")}
                  </p>
                </div>
              </button>
            ) : null}

            <p className=" text-[12px] !font-[400] montserrat lg:text-[20px]">
              {t("home.We provide electricity across the country, with")} <br />{" "}
            </p>
            <div className="relative lg:w-[480px]">
              <input
                placeholder={t("home.Search for Services, providers......")}
                type="text"
                autoComplete="off"
                value={inputText}
                onClick={() => setOpenSearchModal(true)}
                onChange={(e) => {
                  setInputText(e.target.value);
                  handleChange(e);
                }}
                className="!text-xs relative font-medium placeholder:text-[#b8b7b7] border !border-[#6F6F6F] !rounded-[50px] w-full h-12 px-4  focus:border-transparent focus:ring focus:ring-transparent active:border-transparent lg:h-full !py-4    focus:outline-none text lg:text-lg"
                onMouseDown={handleSearchInputClick}
              />
              <div className="absolute flex items-center justify-center w-8 h-8 rounded-full top-2 right-2 lg:top-2 lg:right-2 bg-primary-color">
                <img
                  loading="lazy"
                  src={searchIcon}
                  className={`w-8 h-8 p-2`}
                  alt=""
                />
              </div>
            </div>
            <div className="flex flex-wrap items-center w-full lg:gap-2 ">
              <p className="font-medium ">{t("home.Top services")}:</p>
              {data?.top_service?.slice(0, 3)?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleViewSubServices(item.service_id)}
                  className="px-1 lg:px-2 lg:w-1/1"
                >
                  <p className="text-[#6F6F6F] border rounded-full px-3 py-2 text-[10px] lg:text-xs  !border-[#6F6F6F]  text-center  cursor-pointer hover:scale-110  duration-1000 ">
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="relative flex items-center justify-center lg:w-1/2">
            <img
              src={phoneImg}
              alt=""
              className="w-[300px] lg:w-[400px] 2xl:w-[600px] lg:h-[800px] lg:mt-10 "
            />
            <p
              className="absolute text-[13.1px] font-normal lg:text-[19.79px] text-white cursor-pointer bottom-12 lg:bottom-12 lg:text-base"
              onClick={getAppLink}
            >
              {t("home.Get the app")} &#8599;
            </p>
          </div>
        </div>
      )}
      {openMap ? (
        <>
          <div
            className="fixed top-0 bottom-0 left-0 z-50 flex items-center justify-center w-full h-full text-white bg-black/75"
            onClick={() => setOpenMap(!openMap)}
          ></div>
          <Map
            setOpen={setOpenMap}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            setLocationName={setLocationName}
          />
        </>
      ) : (
        openLocation &&
        access_token && (
          <>
            <div
              className="fixed top-0 bottom-0 left-0 z-40 flex items-center justify-center w-full h-full text-white bg-black/75"
              onClick={() => setOpenLocation(!openLocation)}
            ></div>
            <form
              className="fixed z-50 flex flex-col items-center justify-center gap-4 mx-3 bg-white translate-y-1/3 top-10 md:top-0 h-96 md:translate-x-1/2 md:w-1/2 md:h-1/2 rounded-3xl "
              onSubmit={handleSelect}
            >
              {openForm && (
                <span
                  className="absolute top-0 left-0 p-3 text-xl"
                  onClick={() => setOpenForm(!openForm)}
                >
                  <img src={ArrowLeft} className="w-5 cursor-pointer" alt="" />
                </span>
              )}
              <span
                className="absolute top-0 right-0 p-3 text-xl cursor-pointer"
                onClick={() => setOpenLocation(!openLocation)}
              >
                x
              </span>
              <img src={locationHome} alt="" className="" />
              <p className="text-xl font-semibold">
                {t("What is Your Location")}
              </p>
              <p className="text-center">
                {t(
                  "We need to know your location in order to suggest nearby services"
                )}
              </p>
              {openForm ? (
                <>
                  <div
                    onClick={() => setOpenMap(!openMap)}
                    className="flex items-center justify-start gap-2 duration-1000 cursor-pointer hover:font-semibold hover:scale-110 "
                  >
                    <div className="p-1  rounded-full bg-[#FF5500]">
                      <img
                        src={locationOrder}
                        className="w-5 p-1 md:p-2 md:w-7 "
                        alt=""
                      />
                    </div>
                    <span>
                      {selectedLocation
                        ? locationName
                          ? locationName
                          : t("Location Selected")
                        : t("Select Location")}
                    </span>
                  </div>

                  <input
                    placeholder={t("Address")}
                    type="text"
                    name="address"
                    required
                    onChange={(e) => setName(e.target.value)}
                    className="w-1/2 p-2 border md:h-14 border-primary-color rounded-xl 2xl:p-5"
                  />
                  <button
                    type="submit"
                    className="w-1/2 py-1 text-white bg-primary-color rounded-3xl"
                  >
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </>
              ) : (
                <>
                  <select
                    className="w-1/2 p-1.5 !border outline-none focus:ring-primary-color focus:ring-2 rounded-xl !border-gray-300"
                    value={locationID ? locationID : ""}
                    onChange={(e) => {
                      setLocationID(e.target.value);
                      setOpenLocation(false);
                    }}
                  >
                    <option value="" disabled>
                      {t("select location")}
                    </option>
                    {locationData?.locations?.map((item, index) => (
                      <option
                        className="w-1/2 p-1.5 !border outline-none focus:ring-primary-color focus:ring-2 rounded-xl !border-gray-300"
                        key={index}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {!openForm && (
                <button
                  onClick={() => setOpenForm(!openForm)}
                  className="w-1/2 py-1 text-white bg-primary-color rounded-3xl"
                >
                  Add New Location
                </button>
              )}
            </form>
          </>
        )
      )}
      {openSearchModal && (
        <Search
          searchLoading={isLoading}
          mutate={mutate}
          openSearchModal={openSearchModal}
          setOpenSearchModal={setOpenSearchModal}
        />
      )}
    </>
  );
};

export default Hero;
