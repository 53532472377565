import { useBlock } from "hooks/chat/useBlock";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { chatStore } from "store/chatStore";
export const useChatBlocks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("chat");
  const { data, isLoading, setQuery, refetch } = useBlock();
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const [chatID, setChatID] = chatStore((state) => [
    state.chatID,
    state.setChatID,
  ]);
  const handleSearch = useDebounce((e) => {
    setQuery(e.target.value);
  }, 1000);

  const formatDate = (dateTimeString) => {
    const parsedDate = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit", // Use 2 digits for hours
      minute: "2-digit", // Use 2 digits for minutes
      hour12: false, // Use 24-hour format
    };

    // Format the date using Intl.DateTimeFormat
    const formattedTime = new Intl.DateTimeFormat("en-US", options).format(
      parsedDate
    );

    // Replace the "/" with "-" and remove the comma
    const formattedTimeModified = formattedTime
      .replace(/\//g, "-")
      .replace(",", "");

    return formattedTimeModified;
  };

  useEffect(() => {
    const unreadCountArray = data?.conversations?.map(
      (item) => item.unread_count
    );
    const calculatedTotalUnreadCount = unreadCountArray?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    if (calculatedTotalUnreadCount !== undefined) {
      setTotalUnreadCount(calculatedTotalUnreadCount);
    }
  }, [data]);
  return {
    t,
    data,
    chatID,
    refetch,
    navigate,
    setChatID,
    isLoading,
    formatDate,
    handleSearch,
    totalUnreadCount,
    setTotalUnreadCount,
    handleDrawer,
    openDrawer,
  };
};
