import { _axios } from "interceptor/http-config";
import { enqueueSnackbar } from "notistack";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";

export const _ChatApi = {
  index: async ({ query }) => {
    return _axios
      .get(`/user/conversation?${query !== "" ? `search=${query}` : ""}`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((err) =>
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  loadMoreMessage: async ({ chatID, lastID, nextPage }) => {
    return _axios
      .get(
        `/user/conversation/${chatID}?page=${nextPage}&count=30${
          lastID !== undefined ? `&oldest_page_id=${lastID}` : ""
        }`
      )
      .catch((err) =>
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  show: async ({ chatID, page }) => {
    return _axios
      .get(`/user/conversation/${chatID}?count=30&page=${page}`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .catch((err) =>
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  markAsRead: async ({ chatID }) => {
    return _axios
      .get(`/user/conversation/mark-as-read/${chatID}`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((err) =>
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
  blocked: async ({ query }) => {
    return _axios
      .get(
        `/user/conversation?${
          query !== "" ? `query=${query}` : ""
        }&block_list=1`,
        {
          headers: {
            ...HttpRequestInterceptor(),
          },
        }
      )
      .then((res) => res.data)
      .catch((err) =>
        enqueueSnackbar("Chat Error", {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },

  sendMessage: async ({ messageId, formData }) => {
    return _axios.post(`/user/conversation?uuid=${messageId}`, formData);
  },
};
