import loader from "assets/icons/svg/chatLoader.svg";
import NotificationsError from "components/error/NotificationsError";
import Loader from "components/styled/Loader";
import useNotification from "../hooks/useNotification";
import NotificationCard from "../components/NotificationCard";
import { Fragment } from "react";
const Notification = () => {
  const {
    t,
    data,
    isLoading,
    formatDate,
    containerRef,
    isFetchingNextPage,
    handelNavigateButton,
  } = useNotification();
  if (isLoading) return <Loader />;
  if (data?.pages[0]?.notifications?.length === 0)
    return <NotificationsError />;
  return (
    <section className="px-2 md:px-4 mt-0 lg:px-0">
      <div className="flex lg:pb-6 pl-3 lg:pl-10 gap-2 lg:!gap-5 items-center relative text-xl border-b text-[14.42px] font-semibold lg:text-[36px] text-[#302F2A]">
        <p className="font-[700] montserrat">{t("Notifications")} </p>
        <p className="absolute h-[2.06px] lg:h-[5.97px] bg-[#302F2A] top-7 lg:top-[68px] w-[123.6px] lg:w-[358.42px]"></p>
      </div>

      <div className="relative flex h-full">
        <div className="w-1/2  h-[600px] rounded-3xl rounded-r-none">
          <div
            ref={containerRef}
            className=" p-2 lg:p-10 absolute w-full max-h-[600px] overflow-scroll flex flex-col gap-1 mt-4 md:mt-5 notifications-scroll"
          >
            {data?.pages?.map((notifications) =>
              notifications?.notifications?.map((item) => (
                <Fragment key={item.id}>
                  <NotificationCard
                    notification={item}
                    formatDate={formatDate}
                    handelNavigateButton={handelNavigateButton}
                  />
                  <p className="!h-0.5 !bg-third-color !w-full text-white ">
                    .
                  </p>
                </Fragment>
              ))
            )}
          </div>
        </div>
      </div>
      {isFetchingNextPage ? (
        <div className="flex items-center justify-center w-full my-3">
          <img src={loader} alt="" />
        </div>
      ) : null}
    </section>
  );
};

export default Notification;
