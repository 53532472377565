import logo from "assets/icons/logo.png";
import Loader from "components/styled/Loader";
import { useVerificationCodeForm } from "./hooks/useVerificationCodeForm";
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import img from "assets/images/Auth/sss.jpg";
import img2 from "assets/images/Auth/ImgRegister.png";
import img3 from "assets/images/Auth/OO.jpg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
const VerificationCodeForm = () => {
  const { t, phone, loading, navigate, onSubmit, handleChange, handleSubmit } =
    useVerificationCodeForm();
  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);
  return (
    <div>
      {loading && <Loader />}

      <div className="fixed top-0 z-50 flex items-center justify-center w-full h-full">
        <div
          className="fixed top-0 z-20 w-screen h-screen cursor-pointer bg-black/75"
          onClick={() => navigate(-1)}
        ></div>
        <div className="z-50 flex items-center justify-center">
          <div className="flex justify-center">
            <div
              className={`text-white hidden md:flex overflow-hidden md:w-[290px] rounded-l-2xl relative z-10 `}
            >
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className="overflow-hidden mySwiper"
              >
                <SwiperSlide>
                  <img
                    src={img}
                    className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img2}
                    className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img3}
                    className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div
              className={`md:rounded-r-xl min-h-[600px] w-full max-h-[600px] md:max-h-[700px] relative z-10
             flex flex-col rounded-2xl md:rounded-none overflow-auto scrollbar-hide bg-white md:w-[575px] p-3 md:p-5 max-md:m-3 md:m-0`}
            >
              <div className="flex justify-end w-full">
                <p
                  className="p-2 font-bold text-[18px] cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  X
                </p>
              </div>
              <div className="flex flex-col items-center justify-start w-full h-full md:p-10">
                {/* <div className="flex justify-center md:hidden">
                  <img src={logo} alt="mibo" className="w-1/2" />
                </div> */}
                <div className="flex flex-col items-center justify-center mb-3">
                  <p className="text-[24px] font-bold">Mibo</p>
                </div>
                <div className="flex justify-center w-full text-center">
                  <p>
                    {t("Enter the 4-digit code sent to you at")} {phone}
                  </p>
                </div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col gap-4"
                >
                  <div className="flex flex-col gap-4 text-[20px]">
                    <label className="mt-4 font-bold text-center">
                      {t("OTP")}
                    </label>
                    <div className="flex justify-between gap-2">
                      {[1, 2, 3, 4].map((index) => (
                        <input
                          key={index}
                          placeholder={t("-")}
                          type="text"
                          name={`code${index}`}
                          maxLength={1}
                          required
                          pattern="[0-9]"
                          onChange={(e) => {
                            handleChange(index, e.target.value);
                            // Move focus to the next input field
                            if (e.target.value !== "" && index < 4) {
                              switch (index) {
                                case 1:
                                  code2Ref.current.focus();
                                  break;
                                case 2:
                                  code3Ref.current.focus();
                                  break;
                                case 3:
                                  code4Ref.current.focus();
                                  break;
                                default:
                                  break;
                              }
                            }
                          }}
                          ref={(ref) => {
                            // Assign the ref to the appropriate codeRef based on the index
                            switch (index) {
                              case 1:
                                code1Ref.current = ref;
                                break;
                              case 2:
                                code2Ref.current = ref;
                                break;
                              case 3:
                                code3Ref.current = ref;
                                break;
                              case 4:
                                code4Ref.current = ref;
                                break;
                              default:
                                break;
                            }
                          }}
                          className="flex w-1/5 text-center border rounded-full md:w-1/6 h-14 placeholder:text-center border-primary-color"
                        />
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col items-center justify-center w-full">
                    <button
                      type="submit"
                      className="relative w-full h-12 text-white rounded-3xl bg-primary-color"
                      disabled={loading}
                    >
                      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        {t("Verfiy Code")}
                      </div>
                    </button>

                    <div className="flex items-center max-md:text-[12px] gap-1 justify-center mt-3">
                      <div className="text-[#666666]">
                        {t("By continuing, you agree to the")}
                      </div>
                      <a
                        href="/settings/terms-conditions"
                        className="font-[400] underline"
                      >
                        {" "}
                        {t("Terms of Service")}
                      </a>
                    </div>
                    <div className="flex items-center gap-1 max-md:text-[12px] justify-center mb-3">
                      <div className="text-[#666666]">
                        {t("and acknowledge you’ve read our")}
                      </div>
                      <a className="font-[400] underline">
                        {" "}
                        {t("Privacy Policy.")}
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationCodeForm;
