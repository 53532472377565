import { _ChatApi } from "api/chat/chat";
import { useState } from "react";
import { useQuery } from "react-query";
export const useBlock = () => {
  const [query, setQuery] = useState("");
  const { data, isLoading, refetch } = useQuery(
    ["chat-blocked", query],
    () => _ChatApi.blocked({ query }).then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
    setQuery,
    refetch,
  };
};
