import { _HomeApi } from "api/home/Index";
import { useQuery } from "react-query";
import { useLocationStore } from "store/useLocationStore";

export const useBestApp = () => {
  const [locationID] = useLocationStore((state) => [state.locationID]);
  let params = {};
  if (locationID) params = { location_id: locationID };
  const { data, isLoading } = useQuery(
    ["best-app", locationID],
    () => _HomeApi.bestApp(params).then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
