import React from "react";
import Services from "./Services/components/Services";

const ServicesLayout = ({ children }) => {
  return (
    <div>
      <Services />
      {children}
    </div>
  );
};

export default ServicesLayout;
