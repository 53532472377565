import { _axios } from "interceptor/http-config";
import { facebookModal } from "store/facebookModal";

const useLoginWithGoogle = (handleClose) => {
  const clientId =
    "561845076106-55h4cn446oopct5obpc6e1hl0de5qasm.apps.googleusercontent.com";
  const [setOpenModal, setAccessToken, setName] = facebookModal((state) => [
    state.setOpenModal,
    state.setAccessToken,
    state.setName,
  ]);
  const googleLogin = async ({ data: response }) => {
    const { data } = await _axios.get(
      `/user/login/google?access_token=${
        response.access_token
      }&device_type=web&device_token=${
        localStorage.getItem("firebaseToke") || ""
      }`
    );
    handleClose();
    setAccessToken(data.profile.access_token);
    setName(data.profile.name);
    if (data.profile.phone === null) setOpenModal(true);
    else {
      localStorage.setItem("access_token", data.profile.access_token);
    }
  };

  return { clientId, googleLogin };
};

export default useLoginWithGoogle;
