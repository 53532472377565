import React from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
const Account = () => {
  const {t}=useTranslation('index')
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      transition={{ ease: "easeInOut", duration: 0.5 }}
      className="hidden text-gray-400 md:font-bold md:text-3xl md:mx-10 md:h-full md:justify-center md:items-center w-1/1 2xl:text-5xl md:flex"
    >
      <p>{t('User Account')}</p>
    </motion.div>
  );
};

export default Account;
