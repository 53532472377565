import StarsRating from "react-star-rate";

const ReviewCard = ({ item }) => {
  return (
    <div className="flex flex-col gap-4 py-2 text-xs md:text-base reviews">
      <p className="font-semibold capitalize">{item.user_name}</p>
      <div className="flex items-center gap-10">
        <p className="">{item.created_at}</p>
        <StarsRating
          name="rate"
          value={item?.rate}
          style={{ width: "10px", padding: "20px" }}
          disabled={true}
        />
      </div>
      <p>{item.comment}</p>
      <div className="w-full h-[0.5px] bg-[#E4BEB7] my-2"></div>
    </div>
  );
};

export default ReviewCard;
