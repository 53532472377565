import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBooking } from "hooks/account/useBooking";
import { _axios } from "interceptor/http-config";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { trackingMap } from "store/trackingMap";
export const useBookingLogic = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("index");
  const [open, setOpen] = useState(false);
  const [changeBg, setChangeBg] = useState("current");
  const { enqueueSnackbar } = useSnackbar();
  const [bookingLoading, setbookingLoading] = useState(false);
  const {
    currentBookingList,
    fetchCurrentBookingNextPage,
    hasCurrentBookingNextPage,
    isCurrentBookingFetchingNextPage,
    isCurrentBookingLoading,
    isCurrentBookingFetching,
    lastBookingList,
    fetchLastBookingNextPage,
    hasLastBookingNextPage,
    isLastBookingFetchingNextPage,
    isLastBookingLoading,
    isLastBookingFetching,
  } = useBooking();
  const queryClient = useQueryClient();
  const setBooking = trackingMap((state) => state.setBooking);

  const handleButtonClick = (type) => {
    setChangeBg(type);
  };
  const changeBookingStatus = useMutation(
    async (id) => {
      const formData = new FormData();
      formData.append("status", "cancel");
      return _axios
        .post(`user/booking/change-status/${id}`, formData)
        .then((response) =>
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          })
        );
    },
    {
      onError: (error, variables, context) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            alert(`${field}: ${errorMessages.join(", ")}`);
          });
        }
      },
      onSettled: () => {
        // This is called after the mutation is complete (whether it succeeded or failed)
        queryClient.invalidateQueries(["booking-current"]);
        setbookingLoading(false);
      },
    }
  );
  const changeStatus = (id) => {
    setbookingLoading(true);
    changeBookingStatus.mutate(id);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return {
    t,
    changeBg,
    navigate,
    changeStatus,
    bookingLoading,
    handleButtonClick,
    setBooking,
    currentBookingList,
    fetchCurrentBookingNextPage,
    hasCurrentBookingNextPage,
    isCurrentBookingFetchingNextPage,
    isCurrentBookingLoading,
    isCurrentBookingFetching,
    lastBookingList,
    open,
    setOpen,
    fetchLastBookingNextPage,
    hasLastBookingNextPage,
    isLastBookingFetchingNextPage,
    isLastBookingLoading,
    isLastBookingFetching,
  };
};
