import React, { useEffect } from "react";
import arrowIcon from "assets/icons/svg/account/ArrowLeft.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import backIcon from "assets/icons/svg/Back.svg";
import { useSupport } from "hooks/account/useSupport";
import Loader from "components/styled/Loader";
const GeTSupport = () => {
  const { t } = useTranslation("account");
  const navigate = useNavigate();
  const { data, isLoading } = useSupport();
  const lang = localStorage.getItem("i18nextLng");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col items-center justify-center w-full h-full lg:flex-row lg:flex">
      <div className="flex items-start w-full h-full lg:hidden">
        <div className="flex gap-5 p-5 mt-5 " onClick={() => navigate(-1)}>
          <button>
            <img src={backIcon} alt="" />
          </button>
          <p className="text-xl font-bold">{t("Get Support")}</p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full rounded-lg lg:p-10 lg:mx-10 lg:bg-third-color ">
        <div className="hidden w-full px-10 lg:flex-row lg:flex gap-14 ">
          <img
            src={arrowIcon}
            alt=""
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
          <p className="text-2xl font-bold">{t("Get Support")}</p>
        </div>
        <div className="flex flex-col w-full gap-5 p-10 lg:px-20 lg:gap-10 ">
          {isLoading ? (
            <Loader />
          ) : (
            <p>
              {lang === "en"
                ? data?.support.message_en ?? t("No Data")
                : data?.support.message_de ?? t("No Data")}
            </p>
          )}
          <div className="flex flex-col items-center gap-5 mt-2 text-lg font-semibold lg:gap-10 lg:items-start lg:text-xl">
            <div>
              <a
                className="py-2 text-white rounded-full shadow-xl lg:px-20 px-14 bg-primary-color"
                href={`mailto:${data?.support.email}`}
              >
                {t("By Email")}
              </a>
            </div>
            <div>
              <a
                className="py-2 border rounded-full shadow-xl lg:px-20 px-14 border-primary-color text-primary-color"
                href={`tel:${data?.support.phone}`}
              >
                {t("By Phone")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeTSupport;
