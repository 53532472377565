import { LoginSocialFacebook } from "reactjs-social-login";
import useLoginWithFacebook from "../hooks/useLoginWithFacebook";
import { useTranslation } from "react-i18next";

const LoginWithFacebook = ({ handleClose }) => {
  const { facebookLogin, redirectURI, appId } =
    useLoginWithFacebook(handleClose);
    
  const { t } = useTranslation("index");

  return (
    <LoginSocialFacebook
      appId={appId}
      fieldsProfile={"name,email"}
      redirect_uri={redirectURI}
      onResolve={facebookLogin}
      onReject={(err) => undefined}
      className="w-full"
    >
      <div className="flex justify-center items-center gap-2 border rounded-3xl !border-black border-1 !w-full py-[13px]">
        <button className="bg-[#316FF6] w-6 h-6 rounded-full text-white text-2xl font-semibold">
          f
        </button>
        <div className="text-[13px] font-[400]">
          {t("Continue with Facebook")}
        </div>
      </div>
    </LoginSocialFacebook>
  );
};

export default LoginWithFacebook;
