import { _AuthApi } from "api/auth";
import PusherClient from "pusher-js";

const { REACT_APP_API_URL } = process.env;

const pusherClient = new PusherClient("7df1762732f1e2acfc38", {
  cluster: "eu",
  appId: "1716588",
  secret: "2db6a86cd6c670517d3a",
  encrypted: true,
  authEndpoint: `${REACT_APP_API_URL}/broadcasting/auth`,
  auth: {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${_AuthApi.getToken()}`,
    },
  },
});

export default pusherClient;
