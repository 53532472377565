import { yupResolver } from "@hookform/resolvers/yup";
import { _AuthApi } from "api/auth";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
let schema = yup.object().shape({
  phone: yup.string().required("phone is required"),
});
export const useResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("auth");
  const { enqueueSnackbar } = useSnackbar();

  const [phone, setPhone] = useState("");
  const [loading, setloading] = useState(false);
  const formOptions = { resolver: yupResolver(schema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const onSubmit = async (e) => {
    setloading(true);
    _AuthApi
      .resetPass({ phone })
      .then((res) =>
        navigate(`/reset-password/check-code/${phone}`, { replace: true })
      )
      ?.finally(() => setloading(false))
      .catch((error) => {
        const errorResponse = error?.response?.data;
        if (!errorResponse) {
          enqueueSnackbar(
            "An unexpected error occurred. Please try again later",
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            }
          );
        }
        if (typeof errorResponse?.message === "object") {
          Object.values(errorResponse.message).forEach((errorMessages) => {
            errorMessages.forEach((errorMessage) => {
              enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            });
          });
        }
        if (typeof errorResponse?.message === "string") {
          enqueueSnackbar(errorResponse?.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
        }
      });
  };

  return {
    t,
    loading,
    navigate,
    handleSubmit,
    onSubmit,
    register,
    setPhone,
    errors,
    phone,
  };
};
