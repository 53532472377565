import locationIcon from "assets/icons/profile/location.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { trackingMap } from "store/trackingMap";
import DefaultImage from "assets/images/profile/defaultImage.png";
import { useQueryClient } from "react-query";

const BookingCard = ({ booking, changeStatus, setOpen }) => {
  const { t } = useTranslation("index");
  const queryClient = useQueryClient();
  const isFetchingCurrent = queryClient.isFetching({
    queryKey: ["booking-current"],
  });
  const isFetchingLast = queryClient.isFetching({ queryKey: ["booking-last"] });
  const setBooking = trackingMap((state) => state.setBooking);
  const navigate = useNavigate();
  const handleBookingShow = (id) => {
    navigate(`/booking/${id}`);
  };
  return (
    <div
      key={booking?.id}
      className={`flex px-1 mx-2 border border-gray-200 rounded-full   cursor-pointer  md:gap-5 md:px-5 md:p-2`}
    >
      <div
        onClick={() => handleBookingShow(booking.id)}
        className="flex items-center justify-center object-cover w-40 rounded-full lg:pl-3 h- lg:items-center lg:mx-0 "
      >
        <img
          src={booking.image ? booking.image : DefaultImage}
          className="object-fill w-20 h-20 rounded-full md:h-32 md:w-32 "
          alt=""
        />
      </div>
      <div className="flex flex-col w-full my-2 rounded-md">
        <p className="w-10/12 text-base md:text-2xl text-primary-color">
          {booking.sub_service_name}
        </p>
        <div className="flex pr-10   mt-2 md:mt-9 text-xs md:text-base  text-[#544C4C]">
          <div
            onClick={() => handleBookingShow(booking.id)}
            className="flex flex-col w-full gap-2 md:flex md:flex-col md:w-full md:gap-10"
          >
            <div className="flex flex-col justify-between h-full gap-2">
              <div className="flex items-start gap-1">
                <img src={locationIcon} alt="" className="w-4" />
                <p>{booking.location ?? t("Location")}</p>
              </div>
              <div>
                <p>
                  {t("Services")}: {booking.service_name ?? "No Services"}
                </p>
              </div>
            </div>
            {/* display on mobile price and status*/}
            <p className="block lg:hidden">
              {t("Price")}: {booking.price}
            </p>
            <p className="block lg:hidden">
              {t("Status")}: {booking.status}
            </p>
            {/* hidden on mobile price and status*/}
          </div>
          {/* hidden on mobile price and status*/}
          <div
            className="flex-col hidden w-full gap-5 pl-2 md:flex "
            onClick={() => handleBookingShow(booking.id)}
          >
            <p>{`${t("Price")}: ${booking.price}€ ${booking.price_type}`}</p>
            <p>
              {t("Status")}: {booking.status}
            </p>
          </div>
          {/* hidden on mobile */}
          {booking.status_value === ("waiting" || "accept") ? (
            <div className="flex items-start justify-center pr-5 mt-5 md:mt-0 md:w-1/2">
              <button
                disabled={isFetchingCurrent || isFetchingLast}
                className="bg-[#FF2626] px-3 py-2 rounded-full text-white hover:scale-110 disabled:cursor-not-allowed"
                onClick={() => changeStatus(booking.id)}
              >
                {t("Cancel")}
              </button>
            </div>
          ) : (
            ""
          )}
          {booking.status_value === "on_the_way" ? (
            <div className="flex items-start justify-center pr-5 mt-5 md:mt-0 md:w-1/2">
              <button
                onClick={() => {
                  setOpen(true);
                  setBooking(booking);
                }}
                className="px-3 py-2 text-white bg-green-400 rounded-full hover:scale-110"
              >
                {t("Map")}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingCard;
