import { useBookingLogic } from "../hooks/useBookingLogic";

const BookingSwitch = ({ changeBg, handleButtonClick }) => {
  const { t } = useBookingLogic();
  return (
    <div className="flex items-center justify-center gap-5 px-5 text-xl md:text-2xl rounded-full md:px-0 md:w-[98%] md:p-5 text-primary-color bg-third-color ">
      <div className="flex items-center justify-center w-full md:px-10 ">
        <button
          name="current"
          value="current"
          onClick={() => handleButtonClick("current")}
          className={`w-24 md:w-full rounded-full md:p-5 py-3 my-2 md:px-0 ${
            changeBg === "current"
              ? "bg-primary-color text-white"
              : "bg-transparent"
          }
      `}
        >
          {t("Current")}
        </button>
      </div>
      <div className="flex items-center justify-center w-full md:px-10">
        <button
          name="past"
          value="past"
          onClick={() => handleButtonClick("past")}
          className={`w-24 md:w-full rounded-full md:p-5 py-3 my-2 md:px-0 ${
            changeBg === "past"
              ? "bg-primary-color text-white"
              : "bg-transparent"
          }`}
        >
          {t("Past")}
        </button>
      </div>
    </div>
  );
};

export default BookingSwitch;
