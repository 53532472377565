import { usePrivacy } from "hooks/account/usePrivacy";
import { useTerms } from "hooks/account/useTerms";
import { _axios } from "interceptor/http-config";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
export const useSettingLogic = () => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const { t } = useTranslation("index");
  const [deActivate, setdeActivate] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const { data, isLoading } = useTerms();
  const { data: privacyData, isLoading: privacyLoading } = usePrivacy();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDeleteAccount = () => {
    setDeleteLoading(true);
    _axios
      .delete("/user/delete-account")
      .then((response) => {
        setDeleteLoading(true);

        if (response.data) {
          Object.keys(response.data).forEach((field) => {
            response.data.errors[field].forEach((errorMessage) => {
              enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            });
          });
        } else if (response.data.message) {
          enqueueSnackbar(response?.data?.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((field) => {
            error.response.data.errors[field].forEach((errorMessage) => {
              enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            });
          });
        } else if (error.response.data.message) {
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
        }
      })
      .finally(() => {
        setDeleteLoading(false);
        setDeleteAccount(false);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return {
    t,
    navigate,
    sectionRef,
    deActivate,
    setdeActivate,
    deleteAccount,
    setDeleteAccount,
    data,
    isLoading,
    privacyData,
    privacyLoading,
    deleteLoading,
    handleDeleteAccount,
  };
};
