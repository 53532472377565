import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "components/styled/Loader";
import { _axios } from "interceptor/http-config";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { appleModal } from "store/appleModal";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Swiper, SwiperSlide } from "swiper/react";
import img2 from "assets/images/Auth/ImgRegister.png";
import img3 from "assets/images/Auth/OO.jpg";
import img from "assets/images/Auth/sss.jpg";
import { Autoplay, Pagination } from "swiper/modules";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import PrivacyPolicyDialog from "components/Dialog/PrivacyPolicyDialog";
import TermsOfUsDialog from "components/Dialog/TermsOfUsDialog";

const schema = yup.object().shape({
  phone: yup
    .string()
    .required("phone is required")
    .min(10, "phone number is not valid"),
  name: yup.string().required("name is required"),
});
const AppleForm = () => {
  const { t } = useTranslation("auth");
  const setOpenModal = appleModal((state) => state.setOpenModal);
  const { mutate } = useMutation((data) => createPost(data));
  const [loading, setLoading] = useState(false);
  const formOptions = { resolver: yupResolver(schema) };
  const [openPricavyDialog, setopenPricavyDialog] = useState(false);
  const [termsOfUs, setOpenTerms] = useState(false);
  const { register, handleSubmit, formState, control } = useForm(formOptions);
  const { errors } = formState;
  async function createPost(data, e) {
    setLoading(true);
    _axios
      .post("/user/complete-profile", data)
      .then((res) => {
        Swal.fire(t("Login successfully"));
        setOpenModal(false);
      })
      .catch((error) => {
        const errorResponse = error?.response?.data;
        if (typeof errorResponse?.message === "object") {
          Object.values(errorResponse.message).forEach((errorMessages) => {
            errorMessages.forEach((errorMessage) => {
              Swal.fire(`${errorMessage}`);
            });
          });
        }
        if (typeof errorResponse?.message === "string") {
          Swal.fire(`${errorResponse?.message}`);
        }
      })
      .finally((res) => {
        setLoading(false);
      });
  }
  const onSubmit = async (input) => {
    mutate(input);
  };
  return (
    <>
      {openPricavyDialog ? (
        <PrivacyPolicyDialog
          setopenPricavyDialog={setopenPricavyDialog}
          openPricavyDialog={openPricavyDialog}
        />
      ) : termsOfUs ? (
        <TermsOfUsDialog setOpenTerms={setOpenTerms} termsOfUs={termsOfUs} />
      ) : (
        <div className="">
          {loading ? (
            <div className="fixed top-0 z-10 w-screen h-screen">
              <Loader />
            </div>
          ) : (
            <div className="fixed top-0 z-50 flex items-center justify-center w-full h-full">
              <div className="relative flex items-center justify-center w-full h-full">
                <div className="relative z-10 flex items-center justify-center w-full h-full">
                  <div
                    className="w-full h-full bg-black/40 !cursor-pointer absolute z-0"
                    onClick={() => setOpenModal(false)}
                  />
                  <div
                    className={`text-white hidden lg:flex overflow-hidden lg:w-[290px] rounded-l-2xl relative z-10 `}
                  >
                    <Swiper
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Autoplay, Pagination]}
                      className="overflow-hidden mySwiper"
                    >
                      <SwiperSlide>
                        <img
                          src={img}
                          className="w-full object-cover max-h-[600px] lg:max-h-[700px]"
                          alt=""
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={img2}
                          className="w-full object-cover max-h-[600px] lg:max-h-[700px]"
                          alt=""
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={img3}
                          className="w-full object-cover max-h-[600px] lg:max-h-[700px]"
                          alt=""
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div
                    className={`lg:rounded-r-xl h-[600px] lg:h-[700px] relative z-10 flex flex-col rounded-2xl lg:rounded-none overflow-auto scrollbar-hide bg-white w-full lg:w-[575px] p-3 lg:p-5 max-lg:m-3 lg:m-0`}
                  >
                    <div
                      className={` md:rounded-r-xl overflow-scroll flex flex-col rounded-lg md:rounded-none bg-white p-3 md:p-5 m-7 md:m-0`}
                    >
                      <div className="flex justify-end w-full">
                        <p
                          className="p-2 font-bold text-[18px] cursor-pointer"
                          onClick={() => setOpenModal(false)}
                        >
                          X
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <p className="text-3xl font-bold">Mibo</p>
                        <p className="text-[27px] max-lg:text-[22px] lg:font-semibold mt-2">
                          {t("Complete your profile")}
                        </p>
                      </div>
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col gap-[17px] 2xl:gap-[20px] mt-4 lg:px-10"
                      >
                        <div className="flex flex-col gap-2">
                          <label className="text-[#666666] !font-[400] md:text-[13.59px]">
                            {t("Name")}
                          </label>
                          <input
                            placeholder={t("Enter Name")}
                            type="text"
                            name="name"
                            {...register("name")}
                            className="md:p-3 border border-primary-color p-2.5 rounded-xl max-md:text-[12px] md:rounded-lg"
                          />
                          {errors.name && (
                            <span className="text-red-500">
                              {errors.name.message}
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col gap-2">
                          <label className="text-[#666666] !font-[400] md:text-[13.59px]">
                            {t("Phone")}
                          </label>
                          <Controller
                            control={control}
                            name="phone"
                            rules={{ required: true }}
                            render={({ field: { ref, ...field } }) => (
                              <PhoneInput
                                {...field}
                                inputExtraProps={{
                                  ref,
                                  required: true,
                                  autoFocus: true,
                                }}
                                country={"de"}
                                regions={"europe"}
                                inputClass="!w-full !h-fit !py-2.5 border border-primary-color max-md:!text-[12px] !rounded-xl md:!rounded-lg"
                              />
                            )}
                          />
                          {errors.phone && (
                            <span className="text-red-500">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col items-center justify-center w-full">
                          <button
                            type="submit"
                            className="relative w-full h-12 text-white rounded-xl bg-primary-color"
                          >
                            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                              {t("Complete Profile")}
                            </div>
                          </button>
                        </div>
                      </form>
                      <div className="flex items-center max-lg:text-[12px] gap-1 justify-center mt-3">
                        <div className="text-[#666666]">
                          {t("By continuing, you agree to the")}
                        </div>
                        <p
                          onClick={() => {
                            setOpenTerms(true);
                          }}
                          className="font-[400] underline cursor-pointer"
                        >
                          {" "}
                          {t("Terms of Service")}
                        </p>
                      </div>
                      <div className="flex items-center gap-1 max-lg:text-[12px] justify-center mb-3">
                        <div className="text-[#666666]">
                          {t("and acknowledge that you have read our")}
                        </div>
                        <p
                          onClick={() => {
                            setopenPricavyDialog(true);
                          }}
                          className="cursor-pointer font-[400] underline"
                        >
                          {" "}
                          {t("Privacy Policy.")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AppleForm;
