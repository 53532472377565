import Modules from "../modules/Modules";

const ChatLayout = ({ children }) => {
  return (
    <div className="flex w-full">
      <div className="">
        <Modules />
      </div>

      {children}
    </div>
  );
};

export default ChatLayout;
