import Loader from "components/styled/Loader";
import { _axios } from "interceptor/http-config";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const DeActivate = ({ setdeActivate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deactivateLoading, setDeActivateLoading] = useState(false);
  const { t } = useTranslation("account");
  const handleDeActivateAccount = () => {
    setDeActivateLoading(true);
    _axios
      .get("/user/deactivate-account")
      .then((response) => {
        setDeActivateLoading(true);

        if (response.data) {
          Object.keys(response.data).forEach((field) => {
            response.data.errors[field].forEach((errorMessage) => {
              enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            });
          });
        } else if (response.data.message) {
          enqueueSnackbar(response?.data?.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((field) => {
            error.response.data.errors[field].forEach((errorMessage) => {
              enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              });
            });
          });
        } else if (error.response.data.message) {
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
          });
        }
      })
      .finally(() => {
        setDeActivateLoading(false);
        setdeActivate(false);
      });
  };
  return (
    <>
      <div
        onClick={() => setdeActivate(false)}
        className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black/50"
      ></div>
      <div className="fixed top-[25%] lg:p-5 flex flex-col lg:justify-around gap-20 lg:gap-0 justify-center z-50 bg-white h-60  p-1 rounded-lg lg:left-1/3 left-0 mx-10 lg:mx-0 lg:w-1/4">
        {deactivateLoading ? (
          <Loader />
        ) : (
          <>
            <p className="text-2xl text-[#213241] text-center">
              {t("Are you Sure you want to deactivate your Account")}
            </p>
            <div
              className="flex justify-center w-full gap-10 text-white"
              onClick={handleDeActivateAccount}
            >
              <button className="py-2 px-14 bg-primary-color rounded-xl">
                {t("Yes")}
              </button>
              <button
                className="py-2 border px-14 border-primary-color rounded-xl text-primary-color"
                onClick={() => setdeActivate(false)}
              >
                {t("No")}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DeActivate;
