import benefitsImg from "assets/images/home/benefits.png";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const Benefits = forwardRef((_, ref) => {
  const { t } = useTranslation("index");
  const details = [
    { body: "why-mibo.1" },
    { body: "why-mibo.2" },
    { body: "why-mibo.3" },
    { body: "why-mibo.4" },
    { body: "why-mibo.5" },
  ];
  return (
    <section ref={ref} className="lg:mt-[100px]">
      <div className="flex flex-col w-full lg:flex-row lg:flex ">
        <div className="flex gap-4 ">
          <img
            src={benefitsImg}
            alt=""
            className=" h-[159px] w-[271px] lg:w-[703px] lg:h-[460px] object-cover rounded-r-[49px]"
          />
          <h1 className="montserrat pr-5 text-[18px] lg:hidden flex items-center justify-center w-7/12 font-[700] text-black">
            {t("home.Why choose mibo?")}
          </h1>
        </div>
        <div className="px-2 lg:px-0 lg:!pl-[60px]">
          <h1 className="montserrat text-[42.5px] w-7/12 font-[700] text-black hidden lg:block">
            {t("home.Why choose mibo?")}
          </h1>
          <div className="flex flex-col gap-4 mt-[32px] Roboto font-[400] pl-3 lg:pl-0">
            {details?.map((item, index) => (
              <div
                key={index}
                className="flex items-center gap-2.5 text-[16px] lg:text-[26px]"
              >
                <p className="w-2 h-2 rounded-full lg:w-3 lg:h-3 bg-fifth-color"></p>
                <p>{t(item.body)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
});

export default Benefits;
