import { useCallback, useEffect, useRef } from "react";
import { searchStore } from "store/searchStore";
import SearchEmpty from "./SearchEmpty";
import SearchLoading from "./SearchLoading";
import ServiceProviderList from "./ServiceProviderList";
import ServicesList from "./ServicesList";
import SubServicesList from "./SubServicesList";
import TabButton from "./TabButton";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDebounce } from "hooks/useDebounce";
import { useState } from "react";
import useSearch from "./useSearch";

const Search = ({ searchLoading, mutate, openSearchModal }) => {
  const { t } = useTranslation();
  const setOpenSearchModal = searchStore((state) => state.setOpenSearchModal);
  const popupRef = useRef(null);
  const { pathname } = useLocation();
  const search = searchStore((state) => state.search);
  const tab = searchStore((state) => state.tab);
  const type = searchStore((state) => state.type);
  const setServicePage = searchStore((state) => state.setServicePage);
  const setSubServicePage = searchStore((state) => state.setSubServicePage);
  const servicePage = searchStore((state) => state.servicePage);
  const subServicePage = searchStore((state) => state.subServicePage);
  const setSearch = searchStore((state) => state.setSearch);
  const clearPage = searchStore((state) => state.clearPage);
  const serviceProviderPage = searchStore((state) => state.serviceProviderPage);
  const clearLists = searchStore((state) => state.clearLists);
  const setInputText = searchStore((state) => state.setInputText);
  const setTab = searchStore((state) => state.setTab);
  const inputText = searchStore((state) => state.inputText);
  const [text, setText] = useState(inputText);

  const { reset } = useSearch();
  useEffect(() => {
    setText(inputText);
  }, [inputText]);

  const setServiceProviderPage = searchStore(
    (state) => state.setServiceProviderPage
  );
  const servicesList = searchStore((state) => state.servicesList);
  const subServicesList = searchStore((state) => state.subServicesList);
  const serviceProvidersList = searchStore(
    (state) => state.serviceProvidersList
  );

  const containerRef = useRef(null);
  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container &&
      container.scrollTop + container.clientHeight >= container.scrollHeight
    ) {
      if (!searchLoading) {
        if (type === "services" && servicesList.length % 10 === 0) {
          mutate({ value: inputText, page: servicePage + 1, type: "services" });
          setServicePage();
        }
        if (type === "sub_services" && subServicesList.length % 10 === 0) {
          mutate({
            value: inputText,
            page: subServicePage + 1,
            type: "sub_services",
          });
          setSubServicePage();
        }
        if (
          type === "service_providers" &&
          serviceProvidersList.length % 10 === 0
        ) {
          mutate({
            value: inputText,
            page: serviceProviderPage + 1,
            type: "service_providers",
          });
          setServiceProviderPage();
        }
      }
    }
  };

  const handleClickOutside = useCallback(
    (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        reset();
        setOpenSearchModal(false);
      }
    },
    [setOpenSearchModal, reset]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        reset();
        setOpenSearchModal(false);
      }
    },
    [setOpenSearchModal, reset]
  );

  useEffect(() => {
    document?.addEventListener("mousedown", handleClickOutside);
    document?.addEventListener("keydown", handleKeyDown);

    return () => {
      document?.removeEventListener("mousedown", handleClickOutside);
      document?.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClickOutside, handleKeyDown]);

  const handleChange = useDebounce((e) => {
    handleSearch(e);
  }, 500);

  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setInputText(value);
    clearPage();
    clearLists();
    setTab("services");
    setSearch(null);
    if (value === "") {
      return;
    }
    mutate({ value, page: 1, type: "" });
  };

  if (searchLoading && !search) return <SearchLoading />;
  if (!search) return null;
  if (
    servicesList.length === 0 &&
    subServicesList.length === 0 &&
    serviceProvidersList.length === 0
  )
    return <SearchEmpty />;
  return (
    <div className="fixed top-0 left-0 flex z-[999999] items-center justify-center w-full h-full bg-black/50">
      <div
        ref={popupRef}
        className="fixed top-[10%] left-1/2 -translate-x-1/2 w-11/12 md:max-w-[75%] flex lg:justify-around gap-20 lg:gap-0 justify-center bg-white p-4 md:p-6 lg:p-10 rounded-lg shadow-xl"
      >
        <div className="w-full">
          {pathname === "/" ? (
            <div className="flex items-center relative justify-start w-full lg:w-3/4 mb-4">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className="absolute left-4"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 19.0002L14.657 14.6572M14.657 14.6572C15.3998 13.9143 15.9891 13.0324 16.3912 12.0618C16.7932 11.0911 17.0002 10.0508 17.0002 9.00021C17.0002 7.9496 16.7932 6.90929 16.3912 5.93866C15.9891 4.96803 15.3998 4.08609 14.657 3.34321C13.9141 2.60032 13.0321 2.01103 12.0615 1.60898C11.0909 1.20693 10.0506 1 8.99996 1C7.94936 1 6.90905 1.20693 5.93842 1.60898C4.96779 2.01103 4.08585 2.60032 3.34296 3.34321C1.84263 4.84354 0.999756 6.87842 0.999756 9.00021C0.999756 11.122 1.84263 13.1569 3.34296 14.6572C4.84329 16.1575 6.87818 17.0004 8.99996 17.0004C11.1217 17.0004 13.1566 16.1575 14.657 14.6572Z"
                  stroke="#908080"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <input
                placeholder={t("Search...")}
                id="search"
                name="search"
                autoComplete="off"
                value={text}
                className={`w-full py-2 ps-10 pe-3 border rounded-md focus:border-transparent focus:ring focus:ring-transparent active:border-transparent border-primary-color`}
                onChange={(e) => {
                  setText(e.target.value);
                  handleChange(e);
                }}
                autoCorrect="off"
              />
            </div>
          ) : null}
          <div className="flex items-center flex-wrap justify-start w-full gap-2 md:gap-4 text-white">
            <TabButton tab="services" resultNumber={servicesList.length} />
            <TabButton
              tab="sub_services"
              resultNumber={subServicesList.length}
            />
            <TabButton
              tab="service_providers"
              resultNumber={serviceProvidersList.length}
            />
          </div>
          <div
            ref={containerRef}
            onScroll={handleScroll}
            className="mt-6 w-full max-h-[500px] overflow-y-auto px-3"
          >
            {tab === "services" ? (
              <ServicesList
                openSearchModal={openSearchModal}
                setOpenSearchModal={setOpenSearchModal}
              />
            ) : tab === "sub_services" ? (
              <SubServicesList
                openSearchModal={openSearchModal}
                setOpenSearchModal={setOpenSearchModal}
              />
            ) : (
              <ServiceProviderList
                openSearchModal={openSearchModal}
                setOpenSearchModal={setOpenSearchModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
