import img2 from "assets/images/Auth/ImgRegister.png";
import img3 from "assets/images/Auth/OO.jpg";
import img from "assets/images/Auth/sss.jpg";

import hiddenPassIcon from "assets/images/hiddenPass.png";
import showPassIcon from "assets/images/showPass.png";
import Loader from "components/styled/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import RegisterWithApple from "./components/RegisterWithApple";
import RegisterWithFacebook from "./components/RegisterWithFacebook";
import RegisterWithGoogle from "./components/RegisterWithGoogle";
import { useRegister } from "./hooks/useRegister";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import PrivacyPolicyDialog from "components/Dialog/PrivacyPolicyDialog";
import TermsOfUsDialog from "components/Dialog/TermsOfUsDialog";
import { Autoplay, Pagination } from "swiper/modules";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Register = ({ setOpenSignUp }) => {
  const {
    t,
    loading,
    handleClose,
    handleSubmit,
    onSubmit,
    googleLoading,
    isAppleDevice,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    showPass,
    password,
    setPassword,
    hanldeShowPass,
    showConfirmPass,
    confirm_password,
    setconfirm_password,
    hanldeShowConfirmPass,
    news_letter,
    setNews_letter,
    Login,
    openPricavyDialog,
    setopenPricavyDialog,
    termsOfUs,
    setOpenTerms,
  } = useRegister({ setOpenSignUp });
  return (
    <>
      {openPricavyDialog ? (
        <PrivacyPolicyDialog
          setopenPricavyDialog={setopenPricavyDialog}
          openPricavyDialog={openPricavyDialog}
        />
      ) : termsOfUs ? (
        <TermsOfUsDialog setOpenTerms={setOpenTerms} termsOfUs={termsOfUs} />
      ) : (
        <div className="z-30">
          {loading ? (
            <Loader />
          ) : (
            <div className="fixed top-0 z-50 flex items-center justify-center w-full h-full px-[12px] md:px-3 ">
              <div
                className="absolute w-full h-full cursor-pointer -z-20"
                onClick={handleClose}
              ></div>
              <div className="flex items-center justify-center overflow-hidden rounded-2xl">
                <div className="flex justify-center">
                  {/* Left */}
                  <div
                    className={`text-white hidden md:flex overflow-hidden md:w-[290px] rounded-l-2xl`}
                  >
                    <Swiper
                      // spaceBetween={30}
                      // centeredSlides={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Autoplay, Pagination]}
                      className="overflow-hidden mySwiper"
                    >
                      <SwiperSlide>
                        <img
                          src={img}
                          className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                          alt=""
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={img2}
                          className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                          alt=""
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <img
                          src={img3}
                          className="w-full object-cover max-h-[600px] md:max-h-[700px]"
                          alt=""
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div
                    className={`rounded-xl md:rounded-none md:rounded-r-xl md:w-[575px] flex flex-col bg-white  p-3 md:m-0 max-h-[600px] md:max-h-[700px] overflow-auto scrollbar-hide`}
                  >
                    <div className="flex justify-end w-full">
                      <p
                        className="p-2 font-bold text-[18px] cursor-pointer"
                        onClick={handleClose}
                      >
                        X
                      </p>
                    </div>
                    {/* <div className="flex justify-center md:hidden">
                    <img src={logo} alt="mibo" className="w-1/2" />
                  </div> */}
                    <div className="flex flex-col items-center justify-center">
                      <p className="text-[24px] font-bold">Mibo</p>
                      <p className="text-[27px] max-md:text-[22px] md:font-semibold mt-2">
                        {t("Create an account")}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center ">
                      <p className="text-sm ">
                        {t("Already have an account?")}
                        <span
                          onClick={Login}
                          className="font-semibold cursor-pointer text-primary-color"
                        >
                          {t("Log in")}
                        </span>
                      </p>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="flex flex-col gap-3 mt-3 xl:px-10 md:gap-10"
                    >
                      <div className="flex flex-col gap-2">
                        <label className="text-[#666666] !font-[400] md:text-[13.59px]">
                          {t("Name")}
                        </label>
                        <input
                          placeholder={t("Enter Name")}
                          type="text"
                          name="name"
                          required
                          id="first_name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          className="md:p-3 p-2.5 rounded-xl max-md:text-[12px] border border-primary-color md:rounded-lg "
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-[#666666] !font-[400] md:text-[13.59px]">
                          {t("Email")}
                        </label>
                        <input
                          placeholder={t("Enter Email")}
                          type="email"
                          name="email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="md:p-3 border border-primary-color p-2.5 rounded-xl max-md:text-[12px] md:rounded-lg"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-[#666666] !font-[400] md:text-[13.59px]">
                          {t("Phone")}
                        </label>
                        <PhoneInput
                          country={"de"}
                          isValid={(value, country) => {
                            return false;
                          }}
                          value={phone}
                          inputProps={{ name: "phone", required: true }}
                          regions={"europe"}
                          inputClass="!w-full !h-fit !py-2.5 border border-primary-color max-md:!text-[12px] !rounded-xl md:!rounded-lg"
                          onChange={(phone) => setPhone(phone)}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="text-[#666666] !font-[400] md:text-[13.59px] relative">
                          {t("Password")}
                        </label>
                        <div className="relative">
                          <input
                            placeholder={t("Enter Password")}
                            type={showPass ? "text" : "password"}
                            id="password"
                            name="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2.5 max-md:text-[12px] rounded-xl  md:p-3 border border-primary-color md:rounded-lg"
                          />
                          <img
                            src={showPass ? showPassIcon : hiddenPassIcon}
                            className={`absolute w-5  cursor-pointer right-2   top-1/2 transform -translate-y-1/2`}
                            alt=""
                            onClick={hanldeShowPass}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="relative text-[#666666] !font-[400] md:text-[13.59px]">
                          {t("Confirm Password")}
                        </label>
                        <div className="relative">
                          <input
                            placeholder={t("Confirm Password")}
                            type={showConfirmPass ? "text" : "password"}
                            id="confirm_password"
                            name="confirm_password"
                            required
                            value={confirm_password}
                            onChange={(e) =>
                              setconfirm_password(e.target.value)
                            }
                            className="w-full md:p-3 p-2.5 max-md:text-[12px] rounded-xl border border-primary-color md:rounded-lg"
                          />
                          <img
                            src={
                              showConfirmPass ? showPassIcon : hiddenPassIcon
                            }
                            className={`absolute w-5 text-black cursor-pointer right-2 top-1/2 transform -translate-y-1/2`}
                            alt=""
                            onClick={hanldeShowConfirmPass}
                          />
                        </div>
                      </div>
                      <div className="flex gap-4">
                        <div className="relative flex flex-row w-8 h-4 rounded-full cursor-pointer ms-1">
                          <input
                            id="news_letter"
                            type="checkbox"
                            name="news_letter"
                            required
                            value={news_letter}
                            onChange={(e) => setNews_letter(e.target.value)}
                            className="absolute w-8 h-4 mt-1 transition-colors duration-300 bg-gray-200 rounded-full appearance-none cursor-pointer peer bg-blue-gray-400 checked:bg-primary-color peer-checked:border-primary-color peer-checked:before:bg-primary-color"
                          />
                          <label
                            htmlFor="news_letter"
                            className="mt-1 before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-primary-color peer-checked:before:bg-primary-color"
                          ></label>
                        </div>
                        <label
                          htmlFor="news_letter"
                          className="w-full max-md:text-[12px] cursor-pointer "
                        >
                          {t(
                            "By creating an account you have to agree with terms & conditions"
                          )}
                        </label>
                      </div>
                      <div className="flex flex-col items-center justify-center w-full">
                        <button
                          type="submit"
                          className={`relative w-full h-12 text-white rounded-3xl bg-primary-color  max-md:text-[14px]`}
                        >
                          <p className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                            {t("Create an account")}
                          </p>
                        </button>
                      </div>
                      <div className="flex items-center justify-center w-full gap-2 text-center md:mt-0">
                        {googleLoading ? (
                          <p>{t("loading")}</p>
                        ) : (
                          <>
                            <RegisterWithGoogle setOpenSignUp={setOpenSignUp} />
                            <RegisterWithFacebook
                              setOpenSignUp={setOpenSignUp}
                            />
                            {isAppleDevice ? (
                              <RegisterWithApple
                                setOpenSignUp={setOpenSignUp}
                              />
                            ) : null}
                          </>
                        )}
                      </div>
                    </form>
                    <div className="flex items-center max-md:text-[12px] gap-1 justify-center mt-3">
                      <div className="text-[#666666]">
                        {t("By continuing, you agree to the")}
                      </div>
                      <p
                        onClick={() => {
                          setOpenTerms(true);
                        }}
                        className="cursor-pointer font-[400] underline"
                      >
                        {" "}
                        {t("Terms of Service")}
                      </p>
                    </div>
                    <div className="flex items-center gap-1 max-md:text-[12px] justify-center mb-3">
                      <div className="text-[#666666]">
                        {t("and acknowledge you’ve read our")}
                      </div>
                      <p
                        onClick={() => {
                          setopenPricavyDialog(true);
                        }}
                        className="cursor-pointer font-[400] underline"
                      >
                        {t("Privacy Policy.")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Register;
