import { _axios } from "interceptor/http-config";
import { appleModal } from "store/appleModal";

const useLoginWithApple = (handleClose) => {
  const setOpenModal = appleModal((state) => state.setOpenModal);
  const redirectURI = "https://mibo.r-link.io";
  const clientID = "io.r-link.mibo";
  const loginWithApple = async ({ data }) => {
    _axios
      .get(
        `/user/login/apple?access_token=${
          data.authorization.code
        }&device_type=web&device_token=${
          localStorage.getItem("firebaseToke") || ""
        }`
      )
      .then((res) => {
        localStorage.setItem("access_token", res.data.profile.access_token);
        handleClose();
        localStorage.setItem("name", res.data.profile.name);
        if (res.data.profile.name === "undefined_user") setOpenModal(true);
      });
  };
  return { redirectURI, clientID, loginWithApple };
};

export default useLoginWithApple;
