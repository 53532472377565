import { yupResolver } from "@hookform/resolvers/yup";
import { _axios } from "interceptor/http-config";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { ImageDataContext } from "store/ImageDataContext";
import { editProfileStore } from "store/editProfile";
import Swal from "sweetalert2";
import * as yup from "yup";
const schema = yup.object().shape({});

export const useEditProfile = () => {
  const { t } = useTranslation("index");
  const queryClient = useQueryClient();
  const formOptions = { resolver: yupResolver(schema) };
  const { register, formState, getValues, handleSubmit } = useForm(formOptions);
  const { errors } = formState;
  const { imagesData, setImagesData } = useContext(ImageDataContext);
  const [editProfile, setEditProfile] = editProfileStore((state) => [
    state.editProfile,
    state.setEditProfile,
  ]);
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const handleImage = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      if (file) {
        reader.onload = (e) => {
          // Find the index of the existing image data
          const index = imagesData.findIndex((image) => image.Image);
          if (index !== -1) {
            // Update the existing image data
            setImagesData((prev) => {
              const updatedImagesData = [...prev];
              updatedImagesData[index] = {
                Image: file,
                ImageLink: e.target.result,
              };
              return updatedImagesData;
            });
          } else {
            // Add new image data
            setImagesData((prev) => [
              ...prev,
              { Image: file, ImageLink: e.target.result },
            ]);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const imageLink = imagesData.find((item) => item.ImageLink);
  const { mutate } = useMutation((data) => createPost(data));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function createPost(data) {
    setLoading(true);
    await _axios
      .post("/user/profile", data, setLoading)
      .then((response) => {
        setLoading(true);
        queryClient.invalidateQueries(["profile"]);

        // Swal.fire(`${response?.data?.message}`);
      })
      .catch((error) => {
        const errorResponse = error?.response?.data;
        if (errorResponse && errorResponse.message) {
          Object.values(errorResponse.message).forEach((errorMessages) => {
            errorMessages.forEach((errorMessage) => {
              Swal.fire(`${errorMessage}`);
            });
          });
        }
      })
      .finally(() => {
        queryClient.invalidateQueries(["profile"]);
        setLoading(false);
      });
  }
  const onSubmit = async (input) => {
    const formData = new FormData();
    imagesData.find((item) => formData.append("image", item.Image) !== null);
    input.name && formData.append("name", input.name);
    input.email && formData.append("email", input.email);
    mutate(formData);
  };
  return {
    t,
    loading,
    confirmPassword,
    getValues,
    setLoading,
    setEditProfile,
    imageLink,
    editProfile,
    handleImage,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setConfirmPassword,
  };
};
