import { useQuery } from "react-query";
import { _HomeApi } from "api/home/Index";

export const useServices = () => {
  const { data, isLoading, isSuccess, isError, error } = useQuery(
    ["services"],
    () => _HomeApi.services().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isSuccess,
    isLoading,
    error,
    isError,
  };
};
