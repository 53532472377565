import { yupResolver } from "@hookform/resolvers/yup";
import { useProfile } from "hooks/profile/useProfile";
import { _axios } from "interceptor/http-config";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

const schema = yup.object().shape({
  rate: yup.string().required("Rate is required"),
  comment: yup.string().required("comment is required"),
});
const useRatingReview = (setOpenRating) => {
  const params = useParams();
  const { t } = useTranslation("index");
  const [selectedRating, setSelectedRating] = useState(null);
  const formOptions = { resolver: yupResolver(schema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(formOptions);
  const { data, isLoading } = useProfile();
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpenRating(false);
  };
  const onSubmit = async (input) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("comment", input.comment);
    formData.append("rate", selectedRating);
    await _axios
      .post(`/user/booking/review/${params.id}`, formData)
      .then((res) => {
        Swal.fire(res?.data.message);
        setOpenRating(false);
      })
      .catch((error) => {
        if (typeof error.response?.data.message === "string") {
          Swal.fire(error.response?.data.message);
        } else if (typeof error.response?.data.message === "object") {
          Swal.fire(
            `${Object.values(error.response?.data.message)
              .flat()
              .map((errorMessage) => `<p>${errorMessage}</p>`)}`
          );
        } else {
          Swal.fire("Check Your Connection");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    t,
    handleClose,
    onSubmit,
    data,
    isLoading,
    loading,
    register,
    handleSubmit,
    errors,
    selectedRating,
    setValue,
    setSelectedRating,
  };
};

export default useRatingReview;
