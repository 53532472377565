import AppRouting from "AppRouting";
import { _AuthApi } from "api/auth";
import chatIcon from "assets/icons/chatIcon.svg";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import useFirebaseNotifications from "pages/Notification/hooks/useFirebaseNotifications";
import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { singinStore } from "store/singinStore";
import "./App.css";
import { useQuery } from "react-query";
import { _axios } from "interceptor/http-config";
import { unreadNotifications } from "store/unreadNotifications";
function App() {
  const [searchParams] = useSearchParams();
  const isLoggedInWithGoogle = searchParams.get("access_token");
  const location = useLocation();
  const [setOpenSignIn] = singinStore((state) => [state.setOpenSignIn]);
  const access_token = _AuthApi.getToken();
  const setCount = unreadNotifications((state) => state.setCount);

  useQuery({
    queryKey: ["unread-notifications", access_token],
    enabled: !!access_token,
    queryFn: async () => {
      const { data } = await _axios.get("/user/notification/unread");
      return data.unread_count;
    },
    onSuccess: (data) => {
      setCount(data);
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLoggedInWithGoogle) {
      window.scrollTo({ top: 10 });
    }
    HttpRequestInterceptor();
  }, [isLoggedInWithGoogle]);

  const navigate = useNavigate();
  useFirebaseNotifications();
  return (
    <div className={`w-full h-full `}>
      <div
        onClick={() => {
          !access_token ? setOpenSignIn(true) : navigate("/chat/message");
        }}
        className={`bg-[#F6F9F9] w-[34.64px] h-[34.64px] lg:w-[70px] lg:h-[70px] rounded-full drop-shadow-md fixed flex items-center justify-center right-1 top-[80%] cursor-pointer hover:scale-105 duration-700 z-50 ${
          location.pathname === "/chat/message" ? "hidden" : ""
        }`}
      >
        <img src={chatIcon} alt="chat icon" />
      </div>
      <AppRouting />
    </div>
  );
}

export default App;
