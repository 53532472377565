import Loader from "components/styled/Loader";
import { useBlockUser } from "./hooks/useBlockUser";

const Block = ({ setChatActions, username, chatID, blockAction }) => {
  const { t, blockLoading, handleBlock } = useBlockUser({
    chatID,
    setChatActions,
  });
  return (
    <>
      {blockLoading && <Loader />}
      <div
        onClick={() => setChatActions(false)}
        className="fixed top-0 left-0 z-10 flex items-center justify-center w-screen h-screen bg-black/50"
      ></div>
      <div className="fixed top-[25%]  flex flex-col lg:justify-around gap-20 lg:gap-0 justify-center z-50 bg-white h-60  p-1 rounded-lg lg:left-1/3 left-0 mx-10 lg:mx-0 lg:w-1/4">
        <p className="text-2xl lg:p-2 text-[#213241] text-center">
          {blockAction === 1
            ? `${t("Are you sure you want to UnBlock")} ${username} ?`
            : ` ${t("Are you sure you want to Block")} ${username} ?`}
        </p>
        <div className="flex justify-center w-full gap-10 text-white">
          <button
            onClick={handleBlock}
            className="py-2 px-14 bg-primary-color rounded-xl"
          >
            {t("Yes")}
          </button>
          <button
            onClick={() => setChatActions(false)}
            className="py-2 border px-14 border-primary-color rounded-xl text-primary-color"
          >
            {t("No")}
          </button>
        </div>
      </div>
    </>
  );
};

export default Block;
