import { _axios } from "interceptor/http-config";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchStore } from "store/searchStore";
import { servicesStore } from "store/servicesStore";

const ServicesList = ({ setOpenSearchModal }) => {
  const servicesList = searchStore((state) => state.servicesList);
  const [setServicesName] = servicesStore((state) => [state.setServicesName]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (servicesList.length === 0)
    return <p className="capitalize text-center text-xl">{t("no result")}</p>;
  const hanldeProfileShow = (id) => {
    navigate(`/services/view/${id}`);
    _axios.get(`/user/sub-service/${id}`);
    setOpenSearchModal(false);
  };

  return servicesList.map((service) => (
    <button
      key={service.id}
      type="button"
      onClick={() => {
        hanldeProfileShow(service.id);
        setServicesName(service.name);
        setOpenSearchModal(false);
      }}
      className="flex items-center w-full gap-10 p-2 px-3 my-4 border rounded-lg"
    >
      <img
        src={service.image}
        className="w-20 h-20"
        alt={service.name}
        loading="lazy"
      />
      <p className="text-xl font-medium text-primary-color">{service.name}</p>
    </button>
  ));
};

export default ServicesList;
