import heartEmpty from "assets/icons/heartEmpty.svg";
import heartFill from "assets/icons/heartFill.svg";
import Background from "assets/images/profile/ProviderBg.png";
import Loader from "components/styled/Loader";
// import Singin from "pages/Auth/Singin/Singin";
import arrow from "assets/icons/svg/Arrow 2.svg";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ProviderHeroSection from "../components/order/ProviderHeroSection";
import { useServiceProviderShow } from "../hooks/useServiceProviderShow";
import Order from "./Order";
import { useTranslation } from "react-i18next";
const ServicesProviderShow = () => {
  const {
    details,
    openSignIn,
    acces_token,
    setOpenSignIn,
    handleFavorite,
    favoriteStatus,
    loadingFavorite,
    servicesProvider,
    servicesProviderLoading,
    navigate,
  } = useServiceProviderShow();
  const { subServiceId, providerId } = useParams();
  const { t } = useTranslation("index");
  const [openPricedetails, setOpenPricedetails] = useState([]);

  // const handleButtonClick = (id) => {
  //   setOpenPricedetails((prev) => {
  //     if (
  //       prev.includes(
  //         servicesProvider?.service_provider?.sub_services.find(
  //           (sub) => sub.id === id
  //         ).id
  //       )
  //     ) {
  //       return prev.filter((item) => item !== id);
  //     } else {
  //       return [...prev, id];
  //     }
  //   });
  // };

  useEffect(() => {
    if (subServiceId) {
      setOpenPricedetails([parseInt(subServiceId)]);
    }
  }, [subServiceId]);
  const orderRef = useRef();
  return (
    <div className="">
      {servicesProviderLoading ? (
        <Loader />
      ) : (
        <>
          {/* <div className="px-1 lg:px-14">
            {servicesName && (
              <div className="flex items-center gap-2 mb-5 lg:gap-4 ">
                <img src={homeIcone} className="w-4 lg:w-7" alt="" />
                <span className="text-xl font-bold">/</span>
                <p className="text-xs font-semibold lg:text-xl">
                  {subServicesName}
                </p>
                <span className="text-xl font-bold">/</span>
                <p className="text-xs font-semibold text-gray-500 lg:text-xl">
                  {serviceProvider}
                </p>
                <span className="text-xl font-bold">/</span>
                <p className="text-xs font-semibold text-primary-color lg:text-xl">
                  {servicesName}
                </p>
              </div>
            )}
          </div> */}
          <div className="max-lg:px-8 px-4 bg-white lg:!pl-20 w-full h-[px] lg:h-[408px] lg:flex lg:flex-row flex-col flex items-center lg:justify-between lg:py-4">
            <div className="relative flex flex-col gap-6 justify-center w-full lg:w-1/2">
              <p className="text-[64px] lg:text-[109.42px] font-[400] Surpass-Holiday text-[#FF2626]">
                {servicesProvider?.service_provider.name}
              </p>
              <div>
                <div className="flex items-center gap-2">
                  <p className="montserrat text-[#4C4C4C]">
                    {t('Services')}:
                  </p>
                  <div className="flex gap-2 ">
                    {servicesProvider?.service_provider?.services.map(
                      (item, index) => (
                        <div
                          key={index}
                         className="!border-primary-color border items-center justify-center flex rounded-[24.61px] px-4 py-1 text-[14.76px] text-primary-color"
                        >
                          <p className="Roboto font-[400] lg:font-[700] text-[12px] lg:!text-[14.76px]">
                            {item.name}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="flex gap-2 pt-4">
                  {acces_token ? (
                    <button
                      className="bg-primary-color text-white w-[83.43px] lg:w-[115.43px] h-[39.43px] lg:h-[43.43px] rounded-[7.03px] cursor-pointer hover:scale-105 duration-700 text-[12px] lg:text-[16.48px] montserrat !font-[500] lg:font-[700]"
                      onClick={() =>
                        orderRef.current.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      {t('Order now')}
                    </button>
                  ) : (
                    <button
                      onClick={() => setOpenSignIn(!openSignIn)}
                      className="bg-primary-color text-white w-[83.43px] lg:w-[115.43px] h-[39.43px] lg:h-[43px] rounded-[7px] cursor-pointer hover:scale-105 duration-700 text-[12px] lg:text-[16.48px] montserrat !font-[500] lg:font-[700]"
                    >
                      {t('Order now')}
                    </button>
                  )}
                  <div className="bg-third-color w-[41.23px] h-[39px] lg:w-[45px] lg:h-[43px] rounded-[7px] flex items-center justify-center ">
                    {!acces_token ? (
                      <img
                        src={heartEmpty}
                        alt=""
                        onClick={() => setOpenSignIn(!openSignIn)}
                        className="w-[21px] h-[19px] lg:h-[17px] lg:w-[19px] cursor-pointer"
                      />
                    ) : loadingFavorite ? (
                      <p className="text-[8px]">{t('Loading...')}</p>
                    ) : (
                      <img
                        src={
                          favoriteStatus[
                            servicesProvider?.service_provider.id
                          ] ||
                          servicesProvider?.service_provider.favourite === 0
                            ? heartEmpty
                            : heartFill
                        }
                        alt=""
                        onClick={() =>
                          handleFavorite(servicesProvider?.service_provider.id)
                        }
                        className="w-[21.77px] cursor-pointer h-[19px] hover:scale-105 lg:h-[17px] lg:w-[19px]"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-black montserrat lg:w-1/2 ">
              <ProviderHeroSection
                details={details}
                Background={Background}
                servicesProviderProfile={servicesProvider}
              />
            </div>
          </div>

          <div className="flex flex-col gap-4 px-2 lg:mt-10 lg:flex-row lg:flex">
            <div className="mt-6 lg:mt-0 lg:!w-1/2 w-full overflow-scroll remove-scroll">
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid lg:!px-[64px] gap-2 max-lg:mx-auto w-full max-lg:px-4 lg:w-10/12">
                {servicesProvider?.service_provider?.sub_services
                  .sort((a, b) => a.id - b.id)
                  .map((item, index) => (
                    <button
                      onClick={() => {
                        // handleButtonClick(item.id);
                        navigate(
                          `/service-provider/profile/${item?.id}/${providerId}`
                        );
                      }}
                      className="duration-300 rounded-md lg:p-2 hover:scale-105 items-center hover:bg-third-color flex p-3 flex-row gap-3 border-b !border-third-color"
                      key={index}
                    >
                      <div className="flex w-[50px] h-[50px] rounded-full p-2">
                        <img
                          src={item.image}
                          alt=""
                          className="object-contain"
                        />
                      </div>
                      <div className="flex flex-col w-full">
                        <div className="flex flex-row items-center w-full gap-4">
                          <p
                            className={`${
                              openPricedetails.includes(item.id)
                                ? "text-primary-color !font-[700]"
                                : "text-black"
                            } text-sm lg:text-base !font-[400] hover:!text-primary-color duration-150`}
                          >
                            {item.name}
                          </p>
                          <img
                            src={arrow}
                            alt=""
                            className={`w-[10.77px] ${
                              openPricedetails.includes(item.id)
                                ? ""
                                : "rotate-[42deg]"
                            }`}
                          />
                        </div>
                        {openPricedetails.includes(item.id) && (
                          <p className="font-[400] text-start text-[12px] lg:p-2 text-[#B5B5B5]">
                            {item.price}€ {item.price_type}
                          </p>
                        )}
                      </div>
                    </button>
                  ))}
              </div>
            </div>
            <div
              ref={orderRef}
              className="flex max-lg:px-6 flex-col w-full lg:w-1/2"
            >
              <div className="">
                <p className="montserrat text-[16px] mt-2 font-[500] lg:text-[24px]">
                  {t('Ready to order')}
                </p>
                <p className="text-[#333333] mt-2  Roboto text-[12px] font-[500] lg:text-[18px] lg:!font-[700]">
                  {t('What is the problem')}
                </p>
                <Order />
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col justify-between w-full px-10 mt-20 lg: md:px-10 2xl:px-40 ">
            <div className="flex flex-col justify-between md:flex-row md:flex ">
              <LocationPriceRes
                LocationIcon={LocationIcon}
                servicesProvider={servicesProvider}
              />
            </div>
          </div>
          <OrderResponsive
            openSignIn={openSignIn}
            setOpenSignIn={setOpenSignIn}
            hanldeViewOrder={hanldeViewOrder}
            acces_token={acces_token}
            servicesProvider={servicesProvider}
          /> */}
        </>
      )}
    </div>
  );
};

export default ServicesProviderShow;
