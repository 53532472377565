import { _AuthApi } from "api/auth";
import Instagram from "assets/icons/footer/Instagram.png";
import facebook from "assets/icons/footer/facebook.png";
import telegram from "assets/icons/footer/telegram.png";
import twitter from "assets/icons/footer/Vector (4).svg";
import linkedin from "assets/icons/footer/Vector (5).svg";
import tiktok from "assets/icons/footer/tiktok-white-icon.png";
import CookiesDialog from "components/Dialog/CookiesDialog";
import PrivacyPolicyDialog from "components/Dialog/PrivacyPolicyDialog";
import TermsOfUsDialog from "components/Dialog/TermsOfUsDialog";
import { useServices } from "hooks/home/useServices";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { footerStore } from "store/footerStore";
import { servicesStore } from "store/servicesStore";
import arrow from "assets/icons/footer/arrow.png";
import { useMutation } from "react-query";
import Swal from "sweetalert2";
import axios from "axios";
const Footer = () => {
  const { t } = useTranslation("footer");

  const { data } = useServices();
  const navigate = useNavigate();
  const [setLabel] = footerStore((state) => [state.setLabel]);
  const [setServicesState, setServicesName] = servicesStore((state) => [
    state.setServicesState,
    state.setServicesName,
  ]);

  const footerItem = [
    {
      head: t("Mibo"),
      body1: t("Home"),
      body2: t("Features"),
      body3: t("Services"),
      body4: <Link to="/faq">{t("FAQs")}</Link>,
    },
    {
      head: t("About"),
      body1: t("Why Mibo"),
      body2: t("Application"),
      body3: t("Repairs"),
    },
  ];
  const footerItemResposnive = [
    {
      head: t("Mibo"),
      body1: t("Home"),
      body2: t("Features"),
      body3: t("Services"),
      body4: (
        <Link
          to="/mobile/faq"
          className="duration-1000 cursor-pointer hover:text-primary-color hover:scale-110"
        >
          {t("FAQs")}
        </Link>
      ),
    },
    {
      head: t("About"),
      body1: t("Why Mibo"),
      body2: t("Application"),
      body3: t("Repairs"),
    },
  ];
  const footerMedia = [
    {
      img: linkedin,
      href: "https://www.linkedin.com/in/mibo-home-services-617696313?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      img: facebook,
      href: "https://www.facebook.com/profile.php?id=61560451116870&mibextid=ZbWKwL",
    },
    { img: Instagram, href: "https://www.instagram.com/mibo.services?igsh=aGEyOXN1aTNpdGl5" },
    { img: tiktok, href: "https://www.tiktok.com/@mibo.services" },
  ];

  const handleItemClick = (item) => {
    setServicesState(item.id);
    navigate(`/services/view/${item.id}`);
    window.scrollTo(0, 0);
    setServicesName(item.name);
  };
  const access_token = _AuthApi.getToken();
  const [openPricavyDialog, setopenPricavyDialog] = useState(false);
  const [termsOfUs, setOpenTerms] = useState(false);
  const [openCookies, setopenCookies] = useState(false);
  const [email, setEmail] = useState("");
  const baseUrl = process.env.REACT_APP_API_URL;
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data) => {
      await axios.post(`${baseUrl}/user/newsletter `, data);
    },
    onSuccess: () => {
      setEmail("");
      Swal.fire({
        icon: "success",
        text: "Thanks for subscribing!",
      });
    },
    onError: (error) => {
      const errorResponse = error?.response?.data;
      if (typeof errorResponse?.message === "string") {
        Swal.fire(errorResponse?.message);
      } else if (typeof errorResponse?.message === "object") {
        Swal.fire(
          `${Object.values(errorResponse?.message)
            .flat()
            .map((errorMessage) => `<p>${errorMessage}</p>`)}`
        );
      } else {
        Swal.fire("Check Your Connection");
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your email address",
      });
      return;
    }
    mutate({ email });
  };

  return (
    <div className="bg-[#333] !w-full">
      {openPricavyDialog && (
        <PrivacyPolicyDialog
          setopenPricavyDialog={setopenPricavyDialog}
          openPricavyDialog={openPricavyDialog}
        />
      )}
      {termsOfUs && (
        <TermsOfUsDialog setOpenTerms={setOpenTerms} termsOfUs={termsOfUs} />
      )}
      {openCookies && (
        <CookiesDialog
          setopenCookies={setopenCookies}
          openCookies={openCookies}
        />
      )}

      <div className="lg:!h-[350px]  justify-around md:flex py-[45px]">
        <div className="hidden md:w-1/2 md:justify-between md:flex">
          {footerItem?.map((item, index) => (
            <div key={index}>
              <div className="flex flex-col px-5 leading-10">
                <h6 className="text-sm font-bold mb-[17px] md:text-[16px] text-white">
                  {item.head}
                </h6>
                {Object.values(item)
                  .slice(1)
                  ?.map((bodyItem, bodyIndex) => (
                    <div key={bodyIndex} className="mb-[12px]">
                      <p
                        className="duration-1000 cursor-pointer text-sm md:text-[16px] text-white opacity-75 hover:text-primary-color hover:scale-110"
                        onClick={() => {
                          setLabel(bodyItem);
                          navigate("/");
                        }}
                      >
                        {bodyItem}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
          <div className="flex flex-col px-5 leading-10 ">
            <h6 className="text-sm font-bold mb-[17px] md:text-[16px] text-white">
              {t("Categories")}
            </h6>

            {data?.services?.map((item, index) => (
              <div className="flex flex-col " key={index}>
                <p
                  onClick={() => handleItemClick(item)}
                  className="duration-1000 cursor-pointer text-sm mb-[12px]  md:text-[16px] text-white opacity-75 hover:text-primary-color hover:scale-110"
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* resposnive Routing */}
        <div className="flex justify-start items-center px-8 gap-20 md:hidden">
          {footerItemResposnive?.map((item, index) => (
            <div key={index}>
              <div className="flex flex-col gap-2 leading-10 md:gap-5">
                <h6 className="text-sm font-bold text-white md:text-xl">
                  {item.head}
                </h6>
                {Object.values(item)
                  .slice(1)
                  .map((bodyItem, bodyIndex) => (
                    <div key={bodyIndex}>
                      <div
                        onClick={() => {
                          setLabel(bodyItem);
                          navigate("/");
                        }}
                        className="!text-white/75"
                      >
                        {bodyItem}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        {/* resposnive Routing */}

        <div className="flex flex-col w-full px-[31px] md:px-20 lg:px-0 lg:w-1/3 md:w-1/2  ">
          <div className="h-[200px] max-md:mt-[33px]  mx-auto w-full flex flex-col md:w-[320px] lg:!w-[394px] bg-[#474747] rounded-[24px] py-[22px] px-[31px] lg:px-[50px]">
            <div className="text-[16px] text-white font-[700] ">
              {t("Subscribe")}
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex mt-[10px] h-[36px] mb-[14px] "
            >
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="p-2 outline-none flex-grow rounded-s-[6px]"
              />
              <button
                disabled={isLoading}
                className="bg-primary-color hover:bg-teal-600 flex justify-center items-center text-white rounded-e-[6px] max-lg:min-w-[40px] lg:w-[58px]"
              >
                {!isLoading ? (
                  <img className="w-3 h-2" src={arrow} alt="send" />
                ) : (
                  "loading"
                )}
              </button>
            </form>
            <div className="text-white/60 text-[12px] leading-[20px]">
              {t("Subscribe-text")}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:!mx-24 h-[1px] border opacity-20 mx-auto"></div>
      <div className="flex bg-[#333] flex-col items-center md:justify-between gap-10 py-[38px] text-white md:gap-0 md:flex-row md:flex md:px-24">
        <div className="flex justify-center md:w-[250px] max-md:hidden"></div>
        <div className="justify-between  hidden gap-10 md:flex">
          {access_token ? (
            <a href="/settings/privacy-policy"> {t("Privacy Policy")}</a>
          ) : (
            <p
              className="duration-1000 cursor-pointer hover:text-primary-color hover:scale-110"
              onClick={() => setopenPricavyDialog(!openPricavyDialog)}
            >
              {t("Privacy Policy")}
            </p>
          )}
          {access_token ? (
            <a href="/settings/cookies" className="hidden md:block">
              {t("Cookies")}
            </a>
          ) : (
            <p
              className="duration-1000 cursor-pointer hover:text-primary-color hover:scale-110"
              onClick={() => setopenCookies(!openCookies)}
            >
              {t("Cookies")}
            </p>
          )}
          {access_token ? (
            <a href="/settings/terms-conditions" className="hidden md:block">
              {t("Term of use")}
            </a>
          ) : (
            <p
              className="duration-1000 cursor-pointer hover:text-primary-color hover:scale-110"
              onClick={() => setOpenTerms(!termsOfUs)}
            >
              {t("Term of use")}
            </p>
          )}
        </div>
        <div className="flex h-full gap-2 md:w-[250px] md:justify-end">
          {footerMedia?.map((item, index) => (
            <a
              href={item.href}
              className={`flex items-center justify-center  text-center duration-1000 border rounded-full ${
                item.img === facebook
                  ? "hover:bg-[#3B5998]"
                  : item.img === Instagram
                  ? "hover:bg-[#df6b56]"
                  : item.img === telegram
                  ? "hover:bg-[#229ED9]"
                  : item.img === twitter
                  ? "hover:bg-[#1DA1F2]"
                  : item.img === linkedin
                  ? "hover:bg-[#1DA1F2]"
                  : ""
              } md:p-1 p-2 2xl:p-3 lg:p-2 border-1`}
              key={index}
            >
              <img src={item.img} alt="" className="object-contain w-4 h-4 " />
            </a>
          ))}
        </div>
        {/* responsive */}

        <div className="flex justify-center gap-10 md:hidden">
          {access_token ? (
            <Link to="/settings/privacy-policy"> {t("Privacy Policy")}</Link>
          ) : (
            <p
              className="duration-1000 cursor-pointer hover:text-primary-color hover:scale-110"
              onClick={() => setopenPricavyDialog(!openPricavyDialog)}
            >
              {t("Privacy Policy")}
            </p>
          )}
          {access_token ? (
            <Link to="/mobile/settings/cookies">{t("Cookies")}</Link>
          ) : (
            <p
              className="duration-1000 cursor-pointer hover:text-primary-color hover:scale-110"
              onClick={() => setopenCookies(!openCookies)}
            >
              {t("Cookies")}
            </p>
          )}
          {access_token ? (
            <Link to="/mobile/settings/terms-conditions">
              {" "}
              {t("Term of use")}
            </Link>
          ) : (
            <p
              className="duration-1000 cursor-pointer hover:text-primary-color hover:scale-110"
              onClick={() => setOpenTerms(!termsOfUs)}
            >
              {t("Term of use")}
            </p>
          )}
        </div>
        {/* responsive */}
      </div>
    </div>
  );
};

export default Footer;
