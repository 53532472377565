import React from "react";
import loader from "assets/icons/svg/loader.svg";

const Loader = () => {
  return (
    <div className="flex items-center justify-center w-full h-screen bg-transparent ">
      <img src={loader} alt="" />
    </div>
  );
};

export default Loader;
