import ArrowLeft from "assets/icons/ArrowLeft.svg";
import locationHome from "assets/icons/home/locationHome.svg";
import locationOrder from "assets/icons/locationOrder.svg";
import homeIcone from "assets/icons/profile/home.svg";
import Start from "assets/icons/star.svg";
import ProviderFiltert from "assets/icons/svg/ProviderFiltert.svg";
import PriceIcon from "assets/icons/svg/priceIcon.svg";
import Map from "components/Map/Map";
import Loader from "components/styled/Loader";
import { _axios } from "interceptor/http-config";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "swiper/css/effect-fade";
import FilterPriceRate from "../components/SubServices/FilterPriceRate";
import ServicesProvider from "../components/SubServices/ServicesProvider";
import SubServicesHeader from "../components/SubServices/SubServicesHeader";
import { useSubServices } from "../hooks/useSubServices";
const SubServices = () => {
  const {
    t,
    data,
    totalPages,
    viewProfile,
    currentPage,
    servicesName,
    subServiceID,
    subServicesData,
    subServicesName,
    handlePageClick,
    servicesProvider,
    subServicesRef,
    setSubServicesName,
    setServiceProvider,
    subServicesLoading,
    servicesProviderLoading,
    openPriceMenu,
    setOpenPriceMenu,
    openRateMenu,
    setOpenRateMenu,
    togglePriceMenu,
    toggleRateMenu,
    locationID,
    setLocationID,
    setOpenSignIn,
    access_token,
  } = useSubServices();
  const [name, setName] = useState("");
  const [openMap, setOpenMap] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [openFilter, setopenFilter] = useState(false);
  const [locationData, setLocationData] = useState();
  const [locationName, setLocationName] = useState("");
  useEffect(() => {
    access_token &&
      _axios
        .get("/user/location", {
          headers: {
            ...HttpRequestInterceptor(),
          },
        })
        .then((res) => setLocationData(res.data));
  }, [access_token]);

  const handleSelect = (e) => {
    e.preventDefault();

    if (!selectedLocation) {
      // Display error message if selectedLocation is empty
      Swal.fire("Error", "Location is required", "error");
      return; // Exit function if selectedLocation is empty
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("lat", selectedLocation.lat);
    formData.append("long", selectedLocation.lng);
    setLoading(true);

    _axios
      .post("/user/location", formData)
      .then(() => setLoading(true))
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const { message } = error.response.data;
          Object.keys(message).forEach((field) => {
            const errorMessages = message[field];
            Swal.fire(`${field}: ${errorMessages.join(", ")}`);
          });
        } else {
          Swal.fire(`Network connection...`);
        }
      })
      .finally(() => {
        setLoading(false);
        setOpenMap(false);
        setOpenLocation(false);
      });
  };
  return (
    <section className="flex flex-col p-2 px-3 lg:flex-row lg:flex lg:px-10">
      {subServicesLoading ? (
        <Loader />
      ) : (
        <>
          {/* Sub Services and Services */}
          <SubServicesHeader
            t={t}
            subServicesRef={subServicesRef}
            servicesName={servicesName}
            homeIcone={homeIcone}
            subServicesName={subServicesName}
            subServicesData={subServicesData}
            servicesProvider={servicesProvider}
            setSubServicesName={setSubServicesName}
          />
          {data.length === 0 ? null : (
            <div className="flex flex-col  max-lg:mt-5 lg:w-11/12">
              <div className="relative flex justify-between w-full">
                <p className="text-[#B5B5B5] text-xs lg:text-xl montserrat font-[400]">
                  {data ? data?.pagination?.total : 0}{" "}
                  {t("Services Provider Available")}
                </p>
                <img
                  src={ProviderFiltert}
                  className={`lg:w-5 w-4  cursor-pointer  ${
                    openFilter
                      ? "rotate-180 duration-700"
                      : "-rotate-0 duration-700"
                  }`}
                  onClick={() => setopenFilter(!openFilter)}
                  alt=""
                />
                {openFilter && (
                  <div className="absolute bg-white lg:right-10 right-5 top-4 drop-shadow-lg w-[234px] h-1/1 rounded-[20px] z-20 py-2">
                    <button
                      className="flex px-3 flex-row items-center justify-between gap-1 text-[#576E82] text-[12px] font-[600]"
                      onClick={() => {
                        !access_token
                          ? setOpenSignIn(true)
                          : setOpenLocation(!openLocation);
                        setopenFilter(!openFilter);
                      }}
                    >
                      <img
                        src={locationHome}
                        className="w-[8px] h-[15px]"
                        alt=""
                      />
                      <span className="">location</span>
                    </button>
                    <FilterPriceRate
                      t={t}
                      Start={Start}
                      togglePriceMenu={togglePriceMenu}
                      openPriceMenu={openPriceMenu}
                      setOpenPriceMenu={setOpenPriceMenu}
                      servicesProvider={servicesProvider}
                      subServiceID={subServiceID}
                      toggleRateMenu={toggleRateMenu}
                      openRateMenu={openRateMenu}
                      setOpenRateMenu={setOpenRateMenu}
                      PriceIcon={PriceIcon}
                    />
                  </div>
                )}
              </div>

              {servicesProviderLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="lg:mt-6">
                    {/* <div className="max-h-screen overflow-scroll remove-scroll"> */}
                    <ServicesProvider
                      t={t}
                      data={data}
                      totalPages={totalPages}
                      currentPage={currentPage}
                      viewProfile={viewProfile}
                      handlePageClick={handlePageClick}
                      setServiceProvider={setServiceProvider}
                    />
                  </div>
                  {/* {data?.service_providers.length > 0 && (
                    <ReactPaginate
                      className="flex justify-center gap-10 my-10 "
                      pageCount={totalPages}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      previousLabel={
                        <img
                          src={currentPage >= 1 ? ArrowLeftBlack : ArrowLeft}
                          alt="Previous"
                          className=""
                        />
                      }
                      nextLabel={
                        <img
                          src={
                            currentPage === totalPages - 1
                              ? ArrowRight
                              : ArrowRightBlack
                          }
                          alt="Next"
                          className="your-icon-styles-here"
                        />
                      }
                      activeClassName={"active"}
                    />
                  )} */}
                </>
              )}
            </div>
          )}
          {openMap ? (
            <>
              <div
                className="fixed top-0 bottom-0 left-0 z-50 flex items-center justify-center w-full h-full text-white bg-black/75"
                onClick={() => setOpenMap(!openMap)}
              ></div>
              <Map
                setOpen={setOpenMap}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                setLocationName={setLocationName}
              />
            </>
          ) : (
            openLocation && (
              <>
                <div
                  className="fixed top-0 bottom-0 left-0 z-40 flex items-center justify-center w-full h-full text-white bg-black/75"
                  onClick={() => setOpenLocation(!openLocation)}
                ></div>
                <form
                  className="fixed z-50 flex flex-col items-center justify-center gap-4 mx-3 bg-white translate-y-1/3 top-10 md:top-0 h-96 md:translate-x-1/2 md:w-1/2 md:h-1/2 rounded-3xl "
                  onSubmit={handleSelect}
                >
                  {openForm && (
                    <span
                      className="absolute top-0 left-0 p-3 text-xl"
                      onClick={() => setOpenForm(!openForm)}
                    >
                      <img
                        src={ArrowLeft}
                        className="w-5 cursor-pointer"
                        alt=""
                      />
                    </span>
                  )}
                  <span
                    className="absolute top-0 right-0 p-3 text-xl cursor-pointer"
                    onClick={() => setOpenLocation(!openLocation)}
                  >
                    x
                  </span>
                  <img src={locationHome} alt="" className="" />
                  <p className="text-xl font-semibold">
                    {t("What is Your Location")}
                  </p>
                  <p className="text-center">
                    {t(
                      "We need to know your location in order to suggest nearby services"
                    )}
                  </p>
                  {openForm ? (
                    <>
                      <div
                        onClick={() => setOpenMap(!openMap)}
                        className="flex items-center justify-start gap-2 duration-1000 cursor-pointer hover:font-semibold hover:scale-110 "
                      >
                        <div className="p-1  rounded-full bg-[#FF5500]">
                          <img
                            src={locationOrder}
                            className="w-5 p-1 md:p-2 md:w-7 "
                            alt=""
                          />
                        </div>
                        <span>
                          {selectedLocation
                            ? locationName
                              ? locationName
                              : t("Location Selected")
                            : t("Select Location")}
                        </span>
                      </div>

                      <input
                        placeholder={t("Address")}
                        type="text"
                        name="address"
                        required
                        onChange={(e) => setName(e.target.value)}
                        className="w-1/2 p-2 border md:h-14 border-primary-color rounded-xl 2xl:p-5"
                      />
                      <button
                        type="submit"
                        className="w-1/2 py-1 text-white bg-primary-color rounded-3xl"
                      >
                        {loading ? t("Loading...") : t("Submit")}
                      </button>
                    </>
                  ) : (
                    <>
                      <select
                        className="w-1/2 p-1.5 !border outline-none focus:ring-primary-color focus:ring-2 rounded-xl !border-gray-300"
                        value={locationID ? locationID : ""}
                        onChange={(e) => {
                          setLocationID(e.target.value);
                          servicesProvider(subServiceID, {
                            sort: "price",
                            dir: "max",
                            locationID: e.target.value,
                          });
                          setOpenLocation(false);
                        }}
                      >
                        <option value="" disabled>
                          {t("select location")}
                        </option>
                        {locationData?.locations?.map((item, index) => (
                          <option
                            className="w-1/2 p-1.5 !border outline-none focus:ring-primary-color focus:ring-2 rounded-xl !border-gray-300"
                            key={index}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  {!openForm && (
                    <button
                      onClick={() => setOpenForm(!openForm)}
                      className="w-1/2 py-1 text-white bg-primary-color rounded-3xl"
                    >
                      {t("Add New Location")}
                    </button>
                  )}
                </form>
              </>
            )
          )}
        </>
      )}
    </section>
  );
};

export default SubServices;
