import React from "react";
import iphone from "assets/images/home/iPhone 13 Pro.png";
import { useServices } from "hooks/home/useServices";
import googlePlay from "assets/images/home/google.png";
import AppStore from "assets/images/home/App.png";
import { useNavigate } from "react-router-dom";
import Loader from "components/styled/Loader";
import { useTranslation } from "react-i18next";

const OfferManyResponsive = () => {
  const { t } = useTranslation("index");
  const { data, isLoading } = useServices();
  const navigate = useNavigate();

  const handleViewSubServices = (id) => {
    navigate(`services/view/${id}`);
  };

  return (
    <div className="flex items-center justify-center px-2 ">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="relative  w-[367px] bg-primary-color h-[314px] rounded-[43.92px] rounded-tr-[43.92px] rounded-r-none">
          <div className="absolute top-0 left-0 flex items-end   justify-end w-[125px] h-[100px] p-2 bg-white !rounded-br-[20.19px]">
            <p className="text-lg leading-2    montserrat text-[#333333] relative ">
              {t("home.We've got you covered!")}{" "}
              <span className="absolute flex w-2 h-2 text-2xl bg-fifth-color top-[68px] rounded-full   right-4 text-fifth-color"></span>
            </p>
          </div>

          {data?.services?.map((item, index) => (
            <div
              key={index}
              onClick={() => handleViewSubServices(item.id)}
              className={`absolute flex items-center hover:scale-110 cursor-pointer duration-1000 w-[83.58px] h-[32.84px] gap-2 px-2 text-[11.56px] font-medium bg-white ${
                index === 0
                  ? "right-12 top-28"
                  : index === 1
                  ? "right-1 top-[175px] "
                  : index === 2
                  ? "right-10 top-[240px] "
                  : index === 3
                  ? "left-14 top-[139px] "
                  : index === 4
                  ? "left-7 top-[200px] "
                  : "left-20 top-[266px]"
              } rounded-[8.01px] drop-shadow-xl text-sixth-color z-10 Roboto`}
            >
              <img src={item.image} className="w-3" alt="" />
              <p>{item.name}</p>
            </div>
          ))}
          <div>
            <img
              src={iphone}
              alt="mibo"
              className="absolute left-[25%]    w-1/2 mx-auto  bottom-0    "
            />
          </div>
          <div className="absolute right-0 top-0 flex items-center gap-2 justify-center   h-[40px]  w-[120px] bg-white rounded-bl-[9.89px] ">
            <a href="" className="w-[50px] h-[20px]  ">
              <img
                src={AppStore}
                className="w-[50px] h-[20px] object-contain"
                alt=""
              />
            </a>
            <a href="" className="w-[50px] h-[20px]">
              <img
                src={googlePlay}
                className="w-[50px] h-[20px] object-contain"
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferManyResponsive;
