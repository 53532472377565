import { create } from "zustand";

export const servicesStore = create((set) => ({
  servicesState: false,
  servicesName: false,
  serviceIcon: "",
  subServices: false,
  subServicesName: false,
  serviceProvider: false,

  setServicesState: (data) => {
    set(() => ({ servicesState: data }));
  },
  setServicesIcon: (data) => {
    set(() => ({ serviceIcon: data }));
  },
  setServicesName: (data) => {
    set(() => ({ servicesName: data }));
  },
  setSubServicesState: (data) => {
    set(() => ({ subServices: data }));
  },
  setSubServicesName: (data) => {
    set(() => ({ subServicesName: data }));
  },
  setServiceProvider: (data) => {
    set(() => ({ serviceProvider: data }));
  },
}));
