import logoutIcon from "assets/icons/profile/logout.png";
import curv from "assets/icons/svg/Union.svg";
import cameraIcon from "assets/icons/svg/account/cameraIcon.svg";
import Loader from "components/styled/Loader";
import { useSideBar } from "../hooks/useSideBar";
import SideBarResponsive from "./SideBarResponsive";
import DefaultImage from "assets/images/profile/defaultImage.png";

const SideBar = () => {
  const {
    t,
    links,
    data,
    navigate,
    imageLink,
    editProfile,
    handleImage,
    handleLogout,
    sideBarActive,
    setEditProfile,
    lougoutLoading,
    setSideBarActive,
  } = useSideBar();
  return (
    <>
      {lougoutLoading ? (
        <div className="absolute z-50 flex items-center justify-center w-full bg-white">
          <Loader />
        </div>
      ) : (
        <div className="flex-col hidden h-full lg:flex">
          <div className="flex width50">
            <div className="flex flex-col h-full gap-10 p-5 shape">
              <img src={curv} alt="" className="shapeContent" />
              <p className="text-2xl text-white">{t("Account")}</p>
              {links.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    // If the clicked item's index does not match the current active index, set the new index and open the sidebar
                    setSideBarActive(index);
                    navigate(item.navigate);
                    setEditProfile(false);
                  }}
                  className={`flex items-center h-24 gap-3 p-4  text-xl text-white rounded-md cursor-pointer lg:w-72 2xl:w-96 hover:border hover:border-white hover:bg-white/20 duration-300 active:border ${
                    sideBarActive === index
                      ? "bg-white/20 border-white border "
                      : ""
                  }`}
                >
                  <img src={item.img} alt="" />
                  <p>{item.title}</p>
                </div>
              ))}
              <div
                onClick={handleLogout}
                className="flex items-center h-24 gap-3 p-4 text-xl text-white rounded-md cursor-pointer lg:w-72 2xl:w-96 hover:border hover:border-white hover:bg-white/20 active:border"
              >
                <img src={logoutIcon} alt="" />
                <p className="">{t("Logout")}</p>
              </div>
            </div>
            {/* //Profile image  */}
            <div className="flex items-center profileImage">
              {editProfile ? (
                <div className="relative ">
                  <img
                    src={
                      imageLink?.ImageLink
                        ? imageLink?.ImageLink
                        : data?.profile?.image
                        ? data?.profile?.image
                        : DefaultImage
                    }
                    alt=""
                    className="rounded-full w-80 h-58 2xl:w-80"
                  />
                  <div className="absolute z-10 flex items-center justify-center w-full h-full transform -translate-x-1/2 -translate-y-1/2 rounded-full 2xl:w-full 2xl:h-full bg-black/30 top-1/2 left-1/2 ">
                    <input
                      type="file"
                      id="image"
                      className="hidden"
                      onChange={(e) => handleImage(e)}
                    />
                    <label htmlFor="image">
                      <img
                        src={cameraIcon}
                        alt=""
                        name="image"
                        className="w-10 h-10"
                      />
                    </label>
                  </div>
                </div>
              ) : (
                <div className=" 2xl:mx-5">
                  <img
                    src={data?.profile?.image || DefaultImage}
                    alt=""
                    className="scale-110 2xl:h-full w-96"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="block lg:hidden">
        <SideBarResponsive />
      </div>
    </>
  );
};

export default SideBar;
