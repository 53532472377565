import PropTypes from "prop-types";
import Arrow from "assets/icons/ArrowDown.svg";
export const FaqItem = ({
  question,
  answer,
  index,
  activeIndex,
  setActiveIndex,
}) => {
  const isOpen = index === activeIndex;

  const toggleAnswer = () => {
    isOpen ? setActiveIndex(null) : setActiveIndex(index);
  };

  return (
    <div className="flex flex-col gap-0 p-2 text-black lg:pl-20 ">
      <div
        className="flex py-2 rounded-lg bg-third-color gap-x-2"
        onClick={toggleAnswer}
      >
        <p className="flex gap-10 cursor-pointer lg:text-xl">{question}</p>
        <img
          src={Arrow}
          alt=""
          className={"h-5 w-5 transform " + (isOpen ? "rotate-180" : "rotate-0")}
        />
      </div>
      {isOpen && (
        <p className="text-sm font-medium" key={index}>
          {answer}
        </p>
      )}
    </div>
  );
};

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func.isRequired,
};
