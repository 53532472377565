import google from "assets/icons/google-icon-logo-svg-vector.svg";
import useLoginWithGoogle from "pages/Auth/Singin/hooks/useLoginWithGoogle";
import { useTranslation } from "react-i18next";
import { LoginSocialGoogle } from "reactjs-social-login";

const RegisterWithGoogle = ({ setOpenSignUp }) => {
  const { t } = useTranslation("auth");
  const { clientId, googleLogin } = useLoginWithGoogle(() =>
    setOpenSignUp(false)
  );

  return (
    <LoginSocialGoogle
      client_id={clientId}
      scope="openid profile email"
      discoveryDocs="claims_supported"
      onResolve={googleLogin}
      onReject={(err) => undefined}
    >
      <button className="flex items-center justify-between w-full px-2.5 py-2.5 border rounded-full !border-black border-1">
        <div>
          <img src={google} alt="" className="w-7 h-7 cursor-pointer" />
        </div>
      </button>
    </LoginSocialGoogle>
  );
};

export default RegisterWithGoogle;
