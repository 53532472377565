import AppStore from "assets/images/home/App.png";
import crossing1 from "assets/images/home/Crossing1.png";
import googlePlay from "assets/images/home/google.png";
import { useBestApp } from "hooks/home/useBestApp";
import { useNavigate } from "react-router-dom";
import CrossinResposnive from "./CrossinResposnive";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
const Crossin = forwardRef((_, ref) => {
  const { data } = useBestApp();
  const navigate = useNavigate();
  const { t } = useTranslation("index");
  return (
    <>
      <div
        ref={ref}
        className="items-center justify-center hidden my-10 lg:flex "
      >
        <div className="flex items-end justify-center rounded-[43.92px]  relative w-[367px]  lg:w-[1264px] bg-primary-color h-[314px] lg:h-[697.23px] ">
          <div className="absolute z-50 flex-col bottom-0 left-0 flex items-end justify-end w-1/4 p-4 bg-white rounded-tr-[23.33px]">
            <div className="flex items-center w-full gap-3 ">
              <a href="https://apps.apple.com/ae/app/mibo-home-services/id6472888774" className="duration-1000 hover:scale-110">
                <img
                  src={AppStore}
                  className="w-[105.81px] h-[34.32px]"
                  alt=""
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.rlink.mibo_user" className="duration-1000 hover:scale-110">
                <img
                  src={googlePlay}
                  className="w-[105.81px] h-[34.32px]"
                  alt=""
                />
              </a>
            </div>
            <div className="text-5xl montserrat text-[#333333] leading-[50px] mt-9">
              {t("home.Meet the mibo")}{" "}
              <p className="absolute w-[49.35px] h-1 bg-[#333333] right-24 top-44"></p>{" "}
              {t("home.Squad!")}
              <span className="text-6xl text-fifth-color">.</span>
            </div>
          </div>

          <div className="flex flex-col  items-center justify-center w-full max-h-full  overflow-scroll mt-[540px] -space-y-44  remove-scroll   !py-20 ">
            {data?.top_service_providers?.map((item, index) => (
              <div
                key={index}
                onClick={() => navigate(`/service-provider/profile/${item.id}`)}
                className={`flex cursor-pointer hover:scale-105 duration-300 mt-[500px] flex-col lg:flex-col relative  ${
                  index % 2 !== 0
                    ? "mr-4 w-full items-end flex z-10"
                    : "lg:justify-start z-30"
                }`}
              >
                <img
                  src={item.image ? item.image : crossing1}
                  alt="mibo"
                  loading="lazy"
                  className="bg-white rounded-[4.65px] lg:rounded-[15.92px] my-2 lg:order-2 lg:w-[328.39px] w-[95.92px] h-[121.5px] lg:h-[415.97px] object-cover"
                />
                <p
                  className={`w-24 capitalize absolute -bottom-10 !right-0 lg:text-[28px] font-[700] text-white text-[8.18px] ${
                    index % 2 === 0 ? " " : ""
                  }`}
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="block lg:hidden">
        <CrossinResposnive
          data={data}
          crossing1={crossing1}
          navigate={navigate}
        />
      </div>
    </>
  );
});

export default Crossin;
