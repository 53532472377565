import React from "react";
import loader from "assets/icons/svg/chatLoader.svg";

const ChatLoader = () => {
  return (
    <div className="flex items-center justify-center w-full h-screen bg-transparent ">
      <img src={loader} alt="" loading="lazy" />
    </div>
  );
};

export default ChatLoader;
