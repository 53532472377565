import { useEffect, useState } from "react";
import { servicesStore } from "store/servicesStore";

const SubServicesHeader = ({
  t,
  subServicesRef,
  subServicesData,
  servicesProvider,
  setSubServicesName,
}) => {
  const subServiceName = servicesStore((state) => state.subServicesName);
  const [arrowActive, setArrowActive] = useState(subServicesData[0]?.id);

  useEffect(() => {
    setArrowActive(subServiceName);
  }, [subServiceName]);

  const handleButtonClick = (item) => {
    console.log("click");
    servicesProvider(item.id);
    setSubServicesName(item.name);
    setArrowActive(item.id);
    subServicesRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    subServicesRef.current.scrollIntoView({ behavior: "smooth" });
  }, [subServicesRef]);
  useEffect(() => {
    if (subServicesData.length > 0) {
      console.log(subServicesData);
      handleButtonClick(subServicesData[0]);
    }
  }, [subServicesData]);
  return (
    <div ref={subServicesRef} className="lg:!w-[581px] lg:min-w-[470px] ">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid lg:!px-[64px] h-1/1 gap-2 lg:!gap-4">
        {subServicesData?.map((item, index) => (
          <div className="flex flex-col" key={index}>
            <button
              className="flex flex-row items-center w-full gap-4 duration-300 rounded-md hover:scale-105 p-2 hover:bg-third-color"
              onClick={() => handleButtonClick(item)}
            >
              <img
                src={item.image}
                alt=""
                className={`w-8 lg:w-8 h-8 lg:h-8 transition-transform object-contain`}
              />
              <p
                className={`text-sm md:text-base text-start lg:text-lg font-[400] Roboto hover:!text-primary-color duration-150 ${
                  item.name === subServiceName
                    ? "text-primary-color font-[700]"
                    : "text-black"
                }`}
              >
                {item.name}
              </p>
            </button>
            <span className="flex w-full h-0.5 opacity-30 bg-[#DCDCDC]" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubServicesHeader;
