import React from "react";
import arrowIcon from "assets/icons/svg/account/ArrowLeft.svg";
import { useNavigate } from "react-router-dom";
import backIcon from "assets/icons/svg/Back.svg";
import { useCoockies } from "hooks/account/useCoockies";
import Loader from "components/styled/Loader";
import { useTranslation } from "react-i18next";

const Cookies = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("index");
  const { data, isLoading } = useCoockies();
  return (
    <div className="flex flex-col items-center justify-center w-full h-full lg:flex-row lg:flex">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex items-start w-full h-full lg:hidden">
            <div className="flex gap-5 p-5 mt-5 " onClick={() => navigate(-1)}>
              <button>
                <img src={backIcon} alt="" />
              </button>
              <p className="text-xl font-bold">{t("Cookies")}</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full rounded-lg lg:p-10 lg:mx-10 lg:bg-third-color lg:mt-40">
            <div className="hidden w-full px-10 lg:flex-row lg:flex gap-14 ">
              <img
                src={arrowIcon}
                alt=""
                onClick={() => navigate(-1)}
                className="cursor-pointer"
              />
              <p className="text-2xl font-bold">{t("Cookies")}</p>
            </div>

            <div
              className="max-h-[700px]  overflow-scroll  whitespace-break-spaces flex flex-col w-full gap-5 p-10 lg:px-20 lg:gap-10 "
              dangerouslySetInnerHTML={{
                __html: data?.cookie?.message ?? t("Cookies"),
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Cookies;
