import backIcon from "assets/icons/svg/Back.svg";
import logoutIcon from "assets/icons/svg/account/logout.svg";
import { useSideBar } from "../hooks/useSideBar";
import Loader from "../../../components/styled/Loader";
const SideBarResponsive = () => {
  const { t, navigate, responsiveLinks, handleLogout, lougoutLoading } =
    useSideBar();
  return (
    <>
      {lougoutLoading ? (
        <Loader />
      ) : (
        <div className="my-5 ">
          <div>
            <div onClick={() => navigate(-1)} className="flex gap-4 p-4">
              <button>
                <img src={backIcon} alt="" />
              </button>
              <p>{t("Account")}</p>
            </div>
            <div className="flex flex-col gap-4">
              {responsiveLinks.map((item, index) => (
                <div
                  key={index}
                  onClick={() => navigate(item.navigate)}
                  className="flex gap-5 p-4 mx-4 rounded-2xl border border-primary-color"
                >
                  <img src={item.img} alt="" />

                  <p>{item.title}</p>
                </div>
              ))}
              <div
                onClick={handleLogout}
                className="flex gap-5 p-4 mx-4 rounded-2xl border border-primary-color"
              >
                <img src={logoutIcon} alt="" />

                <p>{t("Logout")}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBarResponsive;
