import { useQuery } from "react-query";
import { _FavouriteApi } from "api/favourite/favourite";

export const useFavourites = () => {
  const { data, isLoading } = useQuery(
    ["favourite"],
    () => _FavouriteApi.index().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
