import { _axios } from "interceptor/http-config";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { chatStore } from "store/chatStore";

export const useAttachment = () => {
  const { t } = useTranslation("chat");
  const [selectedImage, setSelectedImage] = useState(null);
  const [chatID, setAttachmentRes] = chatStore((state) => [
    state.chatID,
    state.setAttachmentRes,
  ]);

  const { data, isLoading } = useQuery(
    ["chat", `id-${chatID}`],
    async () => {
      return await _axios
        .get(`/user/conversation/${chatID}`)
        .then((res) => res.data?.conversations);
    },
    {}
  );

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  return {
    t,
    data,
    chatID,
    openModal,
    isLoading,
    closeModal,
    selectedImage,
    setAttachmentRes,
  };
};
