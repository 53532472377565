import chatIcon from "assets/icons/navBar/chatIcon.png";
import favoriteIcon from "assets/icons/navBar/favofitIcon.png";
import notificationIcon from "assets/icons/navBar/notificationIcon.png";
import profileIcon from "assets/icons/navBar/profileIcon.png";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { notificationStore } from "store/notificationStore";
export const useUserSettings = ({ isHovered, setIsHovered }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasNotification = notificationStore((state) => state.hasNotification);
  const setHasNotification = notificationStore(
    (state) => state.setHasNotification
  );
  const { t } = useTranslation("navbar");
  const handleMouseEnter = (component) => {
    setIsHovered({ ...isHovered, [component]: true });
  };
  const handleMouseLeave = (component) => {
    setIsHovered({ ...isHovered, [component]: false });
  };
  const setting = [
    {
      navigate: "/favorite",
      mouseEnter: "favorite",
      mouseLeave: "favorite",
      pathname: "/favorite",
      img: favoriteIcon,
      hover: isHovered?.favorite,
      body: t("favorite"),
    },
    {
      navigate: "/chat/message",
      mouseEnter: "chat",
      mouseLeave: "chat",
      pathname: "/chat/message",
      img: chatIcon,
      hover: isHovered?.chat,
      body: t("chat"),
    },
    {
      navigate: "/notification",
      mouseEnter: "notification",
      mouseLeave: "notification",
      pathname: "/notification",
      img: notificationIcon,
      hover: isHovered?.notification,
      body: t("notification"),
    },
    {
      navigate: "/account",
      mouseEnter: "account",
      mouseLeave: "account",
      pathname: "/account",
      img: profileIcon,
      hover: isHovered?.account,
      body: t("account"),
    },
  ];
  return {
    setting,
    navigate,
    location,
    hasNotification,
    setHasNotification,
    handleMouseEnter,
    handleMouseLeave,
  };
};
