import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ProfileImage from "assets/images/profile/ProfileImage.png";
import curv from "assets/icons/svg/account/curvRes.svg";
import backIcon from "assets/icons/svg/Back.svg";

import { useProfile } from "hooks/profile/useProfile";
import Loader from "components/styled/Loader";
import EditProfile from "../components/EditProfile";
import { editProfileStore } from "store/editProfile";
import { useTranslation } from "react-i18next";
const Profile = () => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const { t } = useTranslation("account");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data, isLoading } = useProfile();
  const [editProfile, setEditProfile] = editProfileStore((state) => [
    state.editProfile,
    state.setEditProfile,
  ]);
  const GoogleLogin = data?.profile?.login_with_social;
  return (
    <div ref={sectionRef} className="w-full h-full ">
      {isLoading && <Loader />}
      {editProfile ? (
        <EditProfile
          setEditProfile={setEditProfile}
          GoogleLogin={GoogleLogin}
        />
      ) : (
        <>
          <div className="flex items-start h-1/6 md:hidden">
            <img src={curv} alt="" className="w-screen" />
            <div
              className="absolute flex gap-5 p-5 mt-5"
              onClick={() => navigate(-1)}
            >
              <button>
                <img src={backIcon} alt="" />
              </button>
              <p className="text-xl font-bold">{t("Profile")}</p>
            </div>
          </div>
          <div className="flex items-center justify-center md:hidden ">
            <img
              src={ProfileImage}
              className="w-32 -translate-y-9 md:-translate-y-9"
              alt=""
            />
          </div>
          {data && (
            <div className="flex flex-col items-center justify-center w-full h-full gap-10">
              <p className="text-3xl font-bold text-primary-color">
                {data?.profile?.name}
              </p>
              <p className="text-xl font-semibold">
                {data?.profile?.phone ?? "phone"}
              </p>
              <p className="text-xl font-semibold">{data?.profile?.email}</p>
              <button
                onClick={() => setEditProfile(true)}
                className="w-1/2 px-5 py-2 my-5 text-xl text-white shadow-2xl rounded-3xl bg-primary-color hover:scale-105"
              >
                {t("Edit Profile")}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
