import checkicon from "assets/icons/chat/checkicon.svg";
import photo from "assets/icons/chat/photo.svg";
import DefaultImage from "assets/images/profile/defaultImage.png";
import Loader from "components/styled/Loader";
import { useChatBlocks } from "../hooks/useChatBlocks";
import Attachment from "./Attachment";
import Conversation from "./Conversation";
import ChatBlockResposnive from "./Responsive/ChatBlockResposnive";

const ChatBlock = () => {
  const {
    t,
    data,
    chatID,
    refetch,
    navigate,
    setChatID,
    isLoading,
    formatDate,
    handleSearch,
    totalUnreadCount,
    setTotalUnreadCount,
    handleDrawer,
    openDrawer,
  } = useChatBlocks();
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="hidden w-full gap-5 px-5 lg:flex-row lg:flex lg:w-full">
          <div className="flex flex-col w-1/2 gap-5 2xl:w-1/3">
            <div className="flex items-center justify-between gap-5 ">
              <p className=" text-lg 2xl:text-2xl text-[#363B45] font-semibold">
                {t("Blocked Users")}
              </p>
              <p className="flex items-center justify-center w-8 h-8 text-lg text-white rounded-full bg-fourth-color">
                {totalUnreadCount}
              </p>
            </div>
            <div className="">
              <input
                type="search"
                placeholder={t("search..")}
                onChange={handleSearch}
                className="w-full p-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:ring-[#707A8F] placeholder:text-[#707A8F]"
              />
              <p className="mt-5 text-3xl text-[#363B45]">{t("latest")}</p>
            </div>
            <div className="max-h-[850px] overflow-scroll  w-full flex flex-col gap-5 message-scroll  ">
              {data?.conversations.length > 0 ? (
                data?.conversations?.map((item, index) => (
                  <div
                    key={index}
                    className="flex gap-2 px-2 cursor-pointer hover:scale-105"
                    onClick={() => {
                      setChatID(item.id);
                      refetch();
                      setTotalUnreadCount(
                        (prevTotal) => prevTotal - item.unread_count
                      );
                    }}
                  >
                    <div className="h-12 rounded-full w-14">
                      <img
                        src={item.user_image ? item.user_image : DefaultImage}
                        alt=""
                        className="object-cover h-12 rounded-full w-14"
                      />
                    </div>
                    <div className="flex flex-col w-full gap-3">
                      <div className="flex justify-between ">
                        <p className="text-sm font-semibold text-[#484E5B]">
                          {item.user_name}
                        </p>
                        <p className="text-[#707A8F] text-sm">
                          {formatDate(item.created_at)}
                        </p>
                      </div>
                      <div className="flex justify-between ">
                        <div className="text-xs flex gap-2 text-[#2A3751]">
                          {item.last_message?.media.length > 0 && (
                            <img
                              src={item.last_message?.media ? photo : ""}
                              alt=""
                              className="w-5"
                            />
                          )}
                          {item.last_message?.content ?? "No Messages"}
                          {item?.last_message?.is_sender === true ? (
                            <img src={checkicon} alt="" className="w-4" />
                          ) : (
                            ""
                          )}
                        </div>
                        {chatID !== item.id ? (
                          <p
                            className={`${
                              item.unread_count === 0 ? "hidden" : "flex"
                            } flex items-center justify-center w-5 h-5 text-xs text-white rounded-full bg-fourth-color `}
                          >
                            {item.unread_count}
                          </p>
                        ) : (
                          <p className="flex items-center justify-center w-5 h-5 text-xs text-white rounded-full ">
                            {item.unread_count === 0}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full font-semibold ">
                  <p>{t("No Blocked Users")}</p>
                </div>
              )}
            </div>
          </div>
          <div className="w-full bg-[#F6F7F9] rounded">
            {chatID && <Conversation />}
          </div>
          <div className="w-1/2 ">{chatID && <Attachment />}</div>
        </div>
      )}

      <ChatBlockResposnive
        t={t}
        data={data}
        chatID={chatID}
        refetch={refetch}
        navigate={navigate}
        setChatID={setChatID}
        isLoading={isLoading}
        formatDate={formatDate}
        totalUnreadCount={totalUnreadCount}
        setTotalUnreadCount={setTotalUnreadCount}
        handleSearch={handleSearch}
        openDrawer={openDrawer}
        handleDrawer={handleDrawer}
      />
    </>
  );
};

export default ChatBlock;
