import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyDKR1ZaGk-Is5GKCln82RM6375QlQwnUa4",
  authDomain: "mibo-9d598.firebaseapp.com",
  projectId: "mibo-9d598",
  storageBucket: "mibo-9d598.appspot.com",
  messagingSenderId: "561845076106",
  appId: "1:561845076106:web:3114a1651d47a7d923ed73",
  measurementId: "G-8ZZ9YZEW7K",
};

initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);
// google notification
export const requestPermissions = async () => {
  // const permission = await Notification.requestPermission();
  // if (permission === "granted") {
  // if (!localStorage.getItem("firebaseToke")) {
  try {
    const fireBaseToken = await getToken(messaging);
    localStorage.setItem("firebaseToke", fireBaseToken);
  } catch (error) {
    console.log("error", error);
  }
  // }
  // }
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
