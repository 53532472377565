const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div
      className="fixed top-0 left-0 flex items-center justify-center w-full h-full p-20 bg-black bg-opacity-75"
      onClick={onClose}
    >
      <div className="flex items-center justify-center w-full">
        <img
          src={imageUrl}
          alt="Zoomed In"
          className="object-fill rounded-md md:w-1/4 md:h-1/4 "
        />
      </div>
    </div>
  );
};

export default ImageModal;
