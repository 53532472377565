import { yupResolver } from "@hookform/resolvers/yup";
import { _AuthApi } from "api/auth";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import * as yup from "yup";
let schema = yup.object().shape({});
export const useVerificationCodeForm = () => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [direction] = settingsStore((state) => [state.direction]);

  const { phone } = useParams();
  const formOptions = { resolver: yupResolver(schema) };
  const { setValue, handleSubmit } = useForm(formOptions);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    const code = [1, 2, 3, 4].map((index) => data[`code${index}`]).join("");

    setLoading(true);
    _AuthApi.verifyCode({ phone, code }).then((res) =>
      navigate(`/reset-password/edit-password/${phone}/${code}`, {
        replace: true,
      })
        ?.finally(() => setLoading(false))
        .catch((error) => {
          const errorResponse = error?.response?.data;
          if (!errorResponse) {
            enqueueSnackbar(
              "An unexpected error occurred. Please try again later",
              {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
              }
            );
          }
          if (typeof errorResponse?.message === "object") {
            Object.values(errorResponse.message).forEach((errorMessages) => {
              errorMessages.forEach((errorMessage) => {
                enqueueSnackbar(errorMessage, {
                  variant: "error",
                  autoHideDuration: 3000,
                  anchorOrigin: { vertical: "bottom", horizontal: "right" },
                });
              });
            });
          }
          if (typeof errorResponse?.message === "string") {
            enqueueSnackbar(errorResponse?.message, {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
            });
          }
        })
    );
  };
  const handleChange = (index, value) => {
    setValue(`code${index}`, value);
  };
  return {
    t,
    phone,
    loading,
    navigate,
    onSubmit,
    direction,
    handleChange,
    handleSubmit,
  };
};
