import { useInfiniteQuery } from "react-query";
import { _NotificationApi } from "api/notification/notification";

export const useNotifications = () => {
  return useInfiniteQuery({
    queryKey: ["booking-current"],
    queryFn: _NotificationApi.index,
    getNextPageParam: (lastPage) => {
      const pagination = lastPage.pagination;
      return pagination.next_page_url
        ? pagination.current_page + 1 || 0
        : undefined;
    },
  });
};
