import React from "react";
import loader from "assets/images/error/favouriteError.svg";

const FavoriteError = () => {
  return (
    <div className="flex items-center justify-center w-full h-full bg-transparent ">
      <img src={loader} alt="" loading="lazy" />
    </div>
  );
};

export default FavoriteError;
