import logo from "assets/icons/logo.png";
import Loader from "components/styled/Loader";
import { useTerms } from "hooks/account/useTerms";
import { useTranslation } from "react-i18next";
const TermsOfUsDialog = ({ termsOfUs, setOpenTerms }) => {
  const { t } = useTranslation("index");
  const { data, isLoading } = useTerms();

  return (
    <div className="fixed top-0 z-50 w-screen h-screen">
      {isLoading ? (
        <div className="fixed top-0 z-10 w-screen h-screen bg-black/50">
          <Loader />
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          <div className="flex items-center justify-center  h-full">
          <div
            className="w-full h-full bg-black/50 !cursor-pointer absolute z-0"
            onClick={() => setOpenTerms(false)}
          ></div>
            <div className="flex justify-center z-50">
                {/* <div
                className={`
                rounded-l-xl text-white hidden md:flex flex-col gap-14 bg-primary-color  md:w-1/2 md:p-4 `}
              >
                <div className="mt-10">
                  <p>{t("Privacy and Policy")}</p>
                </div>
                <div>
                  <p>{t("Welcome to Mibo")}</p>
                </div>
                <div>
                  <p>
                    {t(
                      "Reach out to your Mibo account by adding your number and password here please."
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-5xl font-bold">MIBO</p>
                </div>
              </div> */}
              <div
                className={`  overflow-auto scrollbar-hide max-h-[600px] md:max-h-[700px] w-full md:w-[800px]
                  flex flex-col  rounded-xl  bg-white p-3 md:!p-6 m-3 md:m-0`}
              >
                <div className="flex justify-end w-full">
                  <p
                    className="p-2 font-bold text-[18px] cursor-pointer"
                    onClick={() => setOpenTerms(!termsOfUs)}
                  >
                    X
                  </p>
                </div>
                {/* <div className="flex justify-center md:hidden">
                  <img src={logo} alt="mibo" className="w-1/2" loading="lazy"/>
                </div> */}
                <p className="text-2xl font-bold">
                  {" "}
                  {data?.term.title ?? t("Terms")}
                </p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.term?.message ?? t("Terms"),
                  }}
                  className="max-h-[700px] whitespace-break-spaces  overflow-scroll flex flex-col w-full gap-5 p-10 lg:px-20 lg:gap-10 "
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsOfUsDialog;
