import Loader from "components/styled/Loader";
import useRatingReview from "hooks/RatingReview/useRatingReview";
import StarsRating from "react-star-rate";
const RatingReview = ({ setOpenRating }) => {
  const {
    t,
    handleClose,
    onSubmit,
    data,
    isLoading,
    loading,
    register,
    handleSubmit,
    errors,
    setValue,
    setSelectedRating,
    selectedRating,
  } = useRatingReview(setOpenRating);

  return (
    <>
      <div className="z-30 ">
        {loading || isLoading ? (
          <div className="z-50 bg-white">
            <Loader />
          </div>
        ) : (
          <div className="fixed top-0 z-50 flex items-center justify-center my-5 left-3 right-3 md:mx-20 lg:mx-40 2xl:mx-80 h-1/1 w-1/1">
            <div className="flex items-center justify-center ">
              <div className="flex justify-center ">
                {/* Left */}
                <div
                  className={` rounded-l-xl   text-white hidden md:flex flex-col gap-14 bg-primary-color  md:w-1/2 p-10`}
                >
                  <div>
                    <p className="text-3xl font-bold text-black">
                      {t("Ratings & Reviews")}
                    </p>
                  </div>

                  {/* <form className="flex flex-col gap-5">
                    <div className="relative flex flex-col">
                      <label
                        htmlFor="username"
                        className="absolute z-10 px-2 text-gray-800 transition-all duration-300 top-2"
                      >
                        {data?.profile?.name}
                      </label>
                      <input
                        type="text"
                        id="username"
                        className="w-full p-2 bg-transparent border-2 border-gray-300 border-solid rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onFocus={(e) => movePlaceholderUp(e.target)}
                        onBlur={(e) => movePlaceholderDown(e.target)}
                      />
                    </div>
                    <div className="relative flex flex-col">
                      <label
                        htmlFor="email"
                        className="absolute z-10 px-2 text-gray-800 transition-all duration-300 top-2"
                      >
                        {data?.profile?.email}
                      </label>
                      <input
                        className="w-full p-2 bg-transparent border-2 border-gray-300 border-solid rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onFocus={(e) => movePlaceholderUp(e.target)}
                        onBlur={(e) => movePlaceholderDown(e.target)}
                      />
                      <p>{data?.profile?.email}</p>
                    </div>
                  </form> */}
                  <div className="flex flex-col gap-5">
                    <div className="relative flex flex-col">
                      <p className="">{t("UserName")}</p>
                      <p className="w-full p-2 bg-transparent border-2 border-gray-400 border-solid rounded-full ">
                        {data?.profile?.name}
                      </p>
                    </div>
                    <div className="relative flex flex-col">
                      <p className="">{t("Email")}</p>
                      <p className="w-full p-2 bg-transparent border-2 border-gray-400 border-solid rounded-full ">
                        {data?.profile?.email}
                      </p>
                    </div>
                  </div>
                </div>
                {/* right */}
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={` gap-10 rounded-xl md:rounded-none md:rounded-r-xl relative rounded-br-xl flex flex-col bg-white  md:w-1/2 p-5  md:m-0 max-h-[700px] md:max-h-[500px] 2xl:max-h-[700px]  overflow-scroll`}
                >
                  <div className="flex justify-end w-full bg-red-400 ">
                    <p
                      className="absolute top-0 right-0 p-1 cursor-pointer "
                      onClick={handleClose}
                    >
                      X
                    </p>
                  </div>
                  <div className="flex flex-col gap-5 md:hidden">
                    <div className="relative flex flex-col">
                      <p className="">{t("UserName")}</p>
                      <p className="w-full p-2 bg-transparent border-2 border-gray-400 border-solid rounded-full ">
                        {data?.profile?.name}
                      </p>
                    </div>
                    <div className="relative flex flex-col">
                      <p className="">{t("Email")}</p>
                      <p className="w-full p-2 bg-transparent border-2 border-gray-400 border-solid rounded-full ">
                        {data?.profile?.email}
                      </p>
                    </div>
                  </div>
                  {/* <Stars /> */}
                  <div className="flex flex-col gap-5 md:gap-10">
                    <div className="flex flex-col">
                      <p>{t('Score:')}</p>

                      <StarsRating
                        name="rate"
                        value={selectedRating}
                        onChange={(value) => {
                          setSelectedRating(value);
                          setValue("rate", value); // Set the value for the 'rate' field
                        }}
                      />
                      {errors.rate && (
                        <span className="text-red-500">
                          {errors.rate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <Stars /> */}

                  <div className="">
                    <textarea
                      type="text"
                      placeholder={t("Review:")}
                      name="comment"
                      {...register("comment")}
                      className="w-full p-2 border rounded-lg border-third-color"
                    />
                    {errors.comment && (
                      <span className="text-red-500">
                        {errors.comment.message}
                      </span>
                    )}
                    <div className="flex flex-col gap-2 mt-2 md:mt-20 ">
                      <button
                        className="w-full p-2 text-white md:w-60 bg-primary-color rounded-3xl"
                        type="submit"
                      >
                        {t('Submit')}
                      </button>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="w-full p-2 text-white md:w-60 bg-primary-color rounded-3xl"
                      >
                        {t('Rate Later')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RatingReview;
