import { _AuthApi } from "api/auth";
import arrow from "assets/icons/ArrowDown.svg";
import logo from "assets/icons/logo.png";
import { useServices } from "hooks/home/useServices";
import { useState } from "react";
import { appleModal } from "store/appleModal";
import { facebookModal } from "store/facebookModal";
import { servicesStore } from "store/servicesStore";
import { settingsStore } from "store/settingsStore";
import AppleForm from "./components/AppleForm";
import FacebookForm from "./components/FacebookForm";
import LoginModal from "./components/LoginModal";
import NavBarResponsive from "./components/NavBarResponsive";
import RegisterModal from "./components/RegisterModal";
import UserSetting from "./components/UserSetting";
import { useNavBarLogic } from "./hooks/useNavBarLogic";
const NavBar = () => {
  const {
    t,
    navBg,
    location,
    navigate,
    isHovered,
    setIsHovered,
    toggleLang,
    hanldeOpenSignUp,
    hanldeOpenSignIn,
    openSignUp,
    setOpenSignUp,
    openSignIn,
    setOpenSignIn,
  } = useNavBarLogic();
  const openAppleModal = appleModal((state) => state.openModal);
  const openFacebookModal = facebookModal((state) => state.openModal);
  const { data } = useServices();
  const [direction] = settingsStore((state) => [state.direction]);
  const [hover, setHover] = useState(false);
  const handleViewSubServices = (id) => {
    navigate(`services/view/${id}`);
  };
  const [setServicesName] = servicesStore((state) => [state.setServicesName]);

  return (
    <div className="relative z-50">
      <nav
        className={`flex justify-between w-full overflow-x-hidden items-center bg-white border !border-t-0 !border-r-0  text-center ${
          location.pathname === "/" ? "" : ""
        } ${navBg ? "z-40 shadow-2xl backdrop-blur-lg" : "z-10"} `}
      >
        <div className="flex items-center gap-10  lg:!px-14">
          <img
            onClick={() => navigate("/")}
            src={logo}
            alt="mibo logo"
            loading="lazy"
            className="cursor-pointer w-28 "
          />
        </div>

        <div className="items-center justify-around hidden lg:flex ">
          {_AuthApi.getToken() ? (
            ""
          ) : (
            <p
              onClick={toggleLang}
              className="mr-10 font-bold text-black duration-1000 cursor-pointer hover:scale-110"
            >
              {direction === "rtl" ? "En" : "De"}
            </p>
          )}
          {_AuthApi.getToken() ? (
            <UserSetting
              isHovered={isHovered}
              setIsHovered={setIsHovered}
              toggleLang={toggleLang}
              direction={direction}
            />
          ) : (
            <>
              <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className=" text-primary-color hover:text-white font-[600] border border-[#E6E6E6] !border-t-0 !border-b-0 !border-r-0 h-16 text-[16px] duration-1000 flex items-center justify-center hover:text-[18px] hover:bg-primary-color gap-2 w-40 cursor-pointer   "
              >
                {t("services")}
                <img src={arrow} className="w-3" alt="Arrow" />
                {hover && (
                  <div className="absolute left-0 !z-50 w-full justify-around  transition-all duration-300 ease-in-out   top-16  flex">
                    <div
                      onMouseEnter={() => setHover(true)}
                      className="flex justify-around w-full mx-20 mt-4 bg-white rounded-lg shadowbox"
                    >
                      {data?.services?.map((item, index) => (
                        <div
                          onClick={() => {
                            handleViewSubServices(item.id);
                            setServicesName(item.name);
                          }}
                          key={index}
                          className="flex flex-col items-center gap-2 py-4 text-black duration-1000 hover:!text-primary-color"
                        >
                          <img src={item.image} alt="mibo" className="w-20" />
                          <p className="">{item.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <p
                className="w-32 font-[600] border border-[#E6E6E6] !border-t-0 !border-b-0 !border-r-0 h-16 text-[16px] duration-1000 flex items-center justify-center hover:text-[18px]  cursor-pointer   text-primary-color"
                onClick={hanldeOpenSignUp}
              >
                {t("Signup")}
              </p>
              <p
                className="w-32 font-[600] border border-[#E6E6E6] !border-t-0 !border-b-0 !border-r-0 h-16 text-[16px] duration-1000 flex items-center justify-center hover:text-[18px] bg-primary-color text-white  cursor-pointer  text-primary-color"
                onClick={hanldeOpenSignIn}
              >
                {t("login")}
              </p>
            </>
          )}
        </div>
        <div className="lg:hidden">
          <NavBarResponsive
            t={t}
            navigate={navigate}
            direction={direction}
            toggleLang={toggleLang}
            openSignIn={openSignIn}
            openSignUp={openSignUp}
            hanldeOpenSignIn={hanldeOpenSignIn}
            hanldeOpenSignUp={hanldeOpenSignUp}
          />
        </div>
      </nav>
      <LoginModal
        openSignIn={openSignIn}
        openSignUp={openSignUp}
        setOpenSignIn={setOpenSignIn}
        setOpenSignUp={setOpenSignUp}
      />
      <RegisterModal openSignUp={openSignUp} setOpenSignUp={setOpenSignUp} />

      {openAppleModal ? <AppleForm /> : null}
      {openFacebookModal ? <FacebookForm /> : null}
    </div>
  );
};

export default NavBar;
