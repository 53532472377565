import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import navbarEn from "./translation/en/navbar.json";
import footerEn from "./translation/en/footer.json";
import indexEn from "./translation/en/index.json";
import accountEn from "./translation/en/account.json";
import chatEn from "./translation/en/chat.json";
import authEn from "./translation/en/auth.json";
// gr
import indexGer from "./translation/ger/index.json";
import navbarGer from "./translation/ger/navbar.json";
import footerGer from "./translation/ger/footer.json";
import accountGer from "./translation/ger/account.json";
import chatGer from "./translation/ger/chat.json";
import authGer from "./translation/ger/auth.json";
let resources = {
  en: {
    navbar: { ...navbarEn },
    footer: { ...footerEn },
    index: { ...indexEn },
    account: { ...accountEn },
    chat: { ...chatEn },
    auth: { ...authEn },
  },
  ger: {
    navbar: { ...navbarGer },
    footer: { ...footerGer },
    index: { ...indexGer },
    account: { ...accountGer },
    chat: { ...chatGer },
    auth: { ...authGer },
  },
};
i18n

  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources,
    debug: false,
    ns: ["translation"],
    defaultNS: "index",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
