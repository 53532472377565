import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ImageDataProvider } from "store/ImageDataContext";
import RTLProvider from "theme/provider/RTLProvider";
import App from "./App";
import "./i18n";
import "./style/output.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

root.render(
  <RTLProvider>
    <SnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ImageDataProvider>
            <App />
          </ImageDataProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </SnackbarProvider>
  </RTLProvider>
);
