import { useQuery } from "react-query";
import { _AccountApi } from "api/account/account";

export const useTerms = () => {
  const { data, isLoading } = useQuery(
    ["terms"],
    () => _AccountApi.terms().then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
