import ArrowLeft from "assets/icons/ArrowLeft.svg";
import locationHome from "assets/icons/home/locationHome.svg";
import locationOrder from "assets/icons/locationOrder.svg";
import Gallery from "assets/icons/profile/gallery.svg";
import Map from "components/Map/Map";
import Loader from "components/styled/Loader";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { useOrder } from "../hooks/useOrder";
const Order = ({ disabledButton = false }) => {
  const {
    t,
    errors,
    control,
    loading,
    register,
    hanldeSubmit,
    handleSubmit,
    selectedPhotos,
    handleFileSelect,
    selectedLocation,
    setSelectedLocation,
    openLocation,
    setOpenLocation,
    handleSelect,
    locationID,
    setLocationID,
    openForm,
    setOpenForm,
    loading1,
    setName,
    locationData,
    openMap,
    setOpenMap,
  } = useOrder();
  const [locationName, setLocationName] = useState("");
  return (
    <>
      <div className="mt-[12px] mb-[44px] relative">
        {openMap && (
          <div
            className="fixed top-0 left-0 z-30 w-screen h-screen bg-black/75 lg:h-screen lg:w-screen"
            onClick={() => {
              if (!selectedLocation) return;
              else setOpenMap(false);
            }}
          ></div>
        )}

        {openMap ? (
          <Map
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            setOpen={setOpenMap}
            setLocationName={setLocationName}
          />
        ) : loading ? (
          <Loader />
        ) : (
          <div className="flex flex-col">
            <div>
              <form
                className="flex flex-col gap-1"
                onSubmit={handleSubmit(hanldeSubmit)}
              >
                <textarea
                  placeholder={t("Please type your message here...")}
                  {...register("description")}
                  rows={5}
                  className="w-full lg:w-10/12 bg-[#FCFCFC] focus:outline-none focus:ring duration-1000 focus:ring-primary-color  p-2 placeholder:text-[#AFAFAF]    rounded-[12px] text-[12px]   "
                ></textarea>
                {errors.description && (
                  <span style={{ color: "red" }} className="text-[12px] ">
                    {errors.description.message}
                  </span>
                )}
                <p className="text-[#333333] mt-2  Roboto text-[12px] font-[500] lg:text-[18px] lg:!font-[700]">
                  {t('Date')}
                </p>
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="yyyy-MM-dd HH:mm"
                      className="text-[12px] w-full lg:w-10/12 h-[38px] lg:h-[72px] bg-[#FCFCFC] focus:outline-none focus:ring duration-1000 focus:ring-primary-color  p-2 placeholder:text-[#AFAFAF]    rounded-[12px] "
                      placeholderText={t("DD/MM")}
                    />
                  )}
                />
                {errors.date && (
                  <span style={{ color: "red" }} className="text-[12px]  ">
                    {errors.date.message}
                  </span>
                )}
                <p className="text-[#333333] mt-2  Roboto text-[12px] font-[500] lg:text-[18px] lg:!font-[700]">
                  {t('Add photo')}
                </p>
                <label
                  className="text-[12px]  w-full lg:w-10/12 h-[38px] lg:h-[72px] cursor-pointer bg-[#FCFCFC] flex items-center gap-2  p-2 placeholder:text-[#AFAFAF]   focus:outline-none focus:ring focus:ring-primary-color    rounded-[12px]  "
                  htmlFor="photo"
                >
                  <img src={Gallery} alt="" className="w-4 lg:w-8" />
                  {t("Photo")}
                </label>
                <Controller
                  name="photo"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <>
                      <input
                        type="file"
                        multiple
                        onChange={(e) => {
                          field.onChange(e);
                          handleFileSelect(e);
                        }}
                        id="photo"
                        className="hidden"
                      />
                    </>
                  )}
                />
                {selectedPhotos && (
                  <div className="flex gap-10 max-w-[300px] overflow-scroll md:max-w-[1000px]">
                    {selectedPhotos.length > 0 &&
                      selectedPhotos.map((photo, index) => (
                        <img
                          key={index}
                          src={URL.createObjectURL(photo)}
                          alt={`Mibo ${index}`}
                          className="w-40 rounded-lg"
                        />
                      ))}
                  </div>
                )}
                <div className="flex items-center flex-row gap-5 !w-[300px] ">
                  {locationID ? (
                    <div className="w-full ">
                      <h2 className="text-[500] lg:font-[700] text-[12px] lg:text-[18px]">
                        {locationID
                          ? locationData?.locations.find(
                              (location) =>
                                location.id.toString() === locationID
                            ).name
                          : t("Choose your location")}
                      </h2>
                    </div>
                  ) : (
                    <p className="font-[500] lg:!font-[700] text-[12px] lg:text-[18px]">
                      {t("Choose your location")}
                    </p>
                  )}
                  <button
                    type="button"
                    onClick={() => setOpenLocation(!openLocation)}
                    className=" w-[36px] rounded-[5px] bg-[#CCE4E7] flex flex-row items-center justify-center hover:scale-105 duration-700 h-[36px] "
                  >
                    <img
                      src={locationOrder}
                      alt="mibo"
                      className="w-[18px] h-[18px]"
                    />
                  </button>
                </div>
                <div className="flex flex-col items-center justify-center lg:flex-row gap-3 lg:flex lg:justify-between py-2">
                  <button
                    type="submit"
                    disabled={disabledButton}
                    className="p-2 text-white disabled:opacity-60 disabled:cursor-not-allowed text-[12px] font-[500] lg:text-[18px] lg:!font-[700] rounded-[12px] w-full lg:w-1/2 h-[51px] !bg-[#59A8AF]"
                  >
                    {t("Confirm order")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      {openMap ? (
        <>
          <div
            className="fixed top-0 bottom-0 left-0 z-50 flex items-center justify-center w-full h-full text-white bg-black/75"
            onClick={() => setOpenMap(!openMap)}
          ></div>
          <Map
            setOpen={setOpenMap}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            setLocationName={setLocationName}
          />
        </>
      ) : (
        openLocation && (
          <>
            <div
              className="fixed top-0 bottom-0 left-0 z-40 flex items-center justify-center w-full h-full text-white bg-black/75"
              onClick={() => setOpenLocation(!openLocation)}
            ></div>
            <form
              className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 flex flex-col items-center justify-center gap-4 mx-3 bg-white h-96 md:w-1/2 md:h-1/2 rounded-3xl "
              onSubmit={handleSelect}
            >
              {openForm && (
                <span
                  className="absolute top-0 left-0 p-3 text-xl"
                  onClick={() => setOpenForm(!openForm)}
                >
                  <img src={ArrowLeft} className="w-5 cursor-pointer" alt="" />
                </span>
              )}
              <span
                className="absolute top-0 right-0 p-3 text-xl cursor-pointer"
                onClick={() => setOpenLocation(!openLocation)}
              >
                x
              </span>
              <img src={locationHome} alt="" className="" />
              <p className="text-xl font-semibold">
                {t("What is Your Location")}
              </p>
              <p className="text-center">
                {t(
                  "We need to know your location in order to suggest nearby services"
                )}
              </p>
              {openForm ? (
                <>
                  <div
                    onClick={() => setOpenMap(!openMap)}
                    className="flex items-center justify-start gap-2 duration-1000 cursor-pointer hover:font-semibold hover:scale-110 "
                  >
                    <div className="p-1  rounded-full bg-[#FF5500]">
                      <img
                        src={locationOrder}
                        className="w-5 p-1 md:p-2 md:w-7 "
                        alt=""
                      />
                    </div>
                    <span>
                      {selectedLocation
                        ? locationName
                          ? locationName
                          : t("Location Selected")
                        : t("Select Location")}
                    </span>
                  </div>

                  <input
                    placeholder={t("Address")}
                    type="text"
                    name="address"
                    required
                    onChange={(e) => setName(e.target.value)}
                    className="w-1/2 p-2 border md:h-14 border-primary-color rounded-xl 2xl:p-5"
                  />
                  <button
                    type="submit"
                    className="w-1/2 py-1 text-white bg-primary-color rounded-3xl"
                  >
                    {loading1 ? t("Loading...") : t("Submit")}
                  </button>
                </>
              ) : (
                <>
                  <select
                    className="w-1/2 p-1.5 !border outline-none focus:ring-primary-color focus:ring-2 rounded-xl !border-gray-300"
                    value={locationID ? locationID : ""}
                    onChange={(e) => {
                      setLocationID(e.target.value);

                      setOpenLocation(false);
                    }}
                  >
                    <option value="" disabled>
                      {t("select location")}
                    </option>

                    {locationData?.locations?.map((item, index) => (
                      <option
                        className="w-1/2 p-1.5 !border outline-none focus:ring-primary-color focus:ring-2 rounded-xl !border-gray-300"
                        key={index}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {!openForm && (
                <button
                  onClick={() => setOpenForm(!openForm)}
                  className="w-1/2 py-1 text-white bg-primary-color rounded-3xl"
                >
                  {t('Add New Location')}
                </button>
              )}
            </form>
          </>
        )
      )}
    </>
  );
};

export default Order;
