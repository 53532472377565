import Loader from "components/styled/Loader";
import React from "react";

const SearchLoading = () => {
  return (
    <div className="fixed left-0 flex z-[999999] items-center justify-center w-screen h-screen top-20 right-20 bg-black/50">
      <div className="fixed top-[10%] left-10 right-10 flex lg:justify-around gap-20 lg:gap-0 justify-center bg-white h-1/1 p-10 rounded-lg shadow-xl z-[9999]">
        <div className="flex items-center justify-center w-full h-full">
          <Loader />
        </div>
      </div>
    </div>
  );
};

export default SearchLoading;
