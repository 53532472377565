import AppStore from "assets/images/home/App.png";
import googlePlay from "assets/images/home/google.png";
import { useTranslation } from "react-i18next";
const CrossinResposnive = ({ data, crossing1, navigate }) => {
  const items = [
    {
      src: data?.top_service_providers[0]?.image,
      name: data?.top_service_providers[0]?.name,
      id: data?.top_service_providers[0]?.id,
    },
    {
      src: data?.top_service_providers[1]?.image,
      name: data?.top_service_providers[1]?.name,
      id: data?.top_service_providers[1]?.id,
    },
    {
      src: data?.top_service_providers[2]?.image,
      name: data?.top_service_providers[2]?.name,
      id: data?.top_service_providers[2]?.id,
    },
    {
      src: data?.top_service_providers[3]?.image,
      name: data?.top_service_providers[3]?.name,
      id: data?.top_service_providers[3]?.id,
    },
  ];
  const { t } = useTranslation("index");

  return (
    <>
      <div className="relative flex items-center justify-center my-10">
        <div className="flex items-end justify-center rounded-[20.19px]  relative w-[367px]    bg-primary-color h-[314px]    gap-3">
          <div className="  absolute  flex-col top-0 left-0 flex items-center justify-center     bg-white rounded-br-[10px]">
            <div className="flex items-center w-[120.57px] h-[36.25px] gap-2   pr-1">
              <a href="s" className="duration-1000 hover:scale-110">
                <img
                  src={AppStore}
                  className="w-[55.61px] h-[16.48px]"
                  alt=""
                />
              </a>
              <a href="s" className="duration-1000 hover:scale-110">
                <img
                  src={googlePlay}
                  className="w-[55.61px] h-[16.48px]"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="flex-col bottom-0 left-0 flex items-end justify-end w-96 h-[65px] bg-white rounded-tr-[23.33px]">
            <div className="text-[16px] relative montserrat text-[#333333] leading-[19.5px] p-2">
              <p>{t("home.Meet the mibo")}</p>
              <p>{t("home.Squad!")}</p>
              <p className="absolute right-7 bottom-3 w-[7.42px] h-[7.42px] bg-fifth-color rounded-full"></p>
            </div>
          </div>

          <div className="flex flex-wrap items-center justify-center -space-y-16 w-[600px] max-h-[314px] overflow-scroll remove-scroll">
            {items.map((item, index) => (
              <div
                key={index}
                onClick={() => navigate(`/service-provider/profile/${item.id}`)}
                className={`flex flex-col lg:flex-col relative ${
                  index % 2 !== 0
                    ? "mr-2 w-full items-end flex"
                    : "lg:justify-start w-full"
                }`}
              >
                <img
                  src={item.src ? item.src : crossing1}
                  alt="mibo"
                  loading="lazy"
                  className="bg-white rounded-[4.65px] my-2 w-[95.92px] h-[121.5px]"
                />
                <p
                  className={`capitalize w-24 lg:text-[28px] font-[700] text-white text-[8.18px]`}
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CrossinResposnive;
