import { useQuery } from "react-query";
import { _HomeApi } from "api/home/Index";
import { useLocationStore } from "store/useLocationStore";

export const useMostAsked = () => {
  const [locationID] = useLocationStore((state) => [state.locationID]);
  let params = {};
  if (locationID) params = { location_id: locationID };
  else params = {};
  const { data, isLoading } = useQuery(
    ["most-asked", locationID],
    () => _HomeApi.mostAsked(params).then((res) => res),
    {
      keepPreviousData: true,
    }
  );
  return {
    data,
    isLoading,
  };
};
