import arrow from "assets/icons/ArrowDown.svg";
import { useServices } from "hooks/home/useServices";
import { useUserSettings } from "layout/hooks/useUserSettings";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { servicesStore } from "store/servicesStore";
import { unreadNotifications } from "store/unreadNotifications";

const UserSetting = ({ isHovered, setIsHovered, toggleLang, direction }) => {
  const { navigate } = useUserSettings({ isHovered, setIsHovered });
  const [hover, setHover] = useState(false);
  const { data } = useServices();
  const { t } = useTranslation("navbar");
  const count = unreadNotifications((state) => state.count);
  const setCount = unreadNotifications((state) => state.setCount);
  const handleViewSubServices = (id) => {
    navigate(`services/view/${id}`);
  };
  const [setServicesName] = servicesStore((state) => [state.setServicesName]);
  return (
    <div className="flex items-center gap-10 bg-white ">
      {/* {setting?.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            navigate(item?.navigate);
            setIsHovered(false);
            item?.body === "notification" &&
              hasNotification &&
              setHasNotification(false);
          }}
        >
          <div className="relative">
            {item?.body === "notification" ? (
              hasNotification ? (
                <RedDot />
              ) : null
            ) : null}
            <img
              onMouseEnter={() => handleMouseEnter(item?.mouseEnter)}
              onMouseLeave={() => handleMouseLeave(item?.mouseLeave)}
              className={`w-6 h-6 md:w-6 md:h-6 cursor-pointer lg:scale-125 relative ${
                location.pathname === item?.pathname
                  ? "bg-white shadow-xl scale-150 drop-shadow-xl rounded-full p-1"
                  : ""
              }`}
              src={item?.img}
              alt=""
            />
            {item?.hover && (
              <p className="absolute inline-block p-2 text-xs text-white transition-opacity duration-300 rounded-full shadow-sm bg-black/60 ">
                {item?.body}
              </p>
            )}
          </div>
        </div>
      ))} */}
      <div className="flex  items-center h-16 text-[#787878] Roboto z-50 ">
        <p
          onClick={() => {
            navigate("/notification");
            setCount(0);
          }}
          className="flex relative hover:bg-primary-color hover:text-white cursor-pointer duration-700 items-center !border-y-0 h-full px-10 border !border-b-0   !border-l-0"
        >
          {count > 0 ? (
            <span className="absolute right-2 top-2 w-4 z-30 h-4 bg-red-500 origin-center rounded-full text-white text-xs">
              {count}
            </span>
          ) : null}
          {t("Notifications")}
        </p>
        <p
          onClick={() => navigate("/favorite")}
          className="flex hover:bg-primary-color hover:text-white cursor-pointer duration-700 items-center !border-y-0 h-full px-10 border !border-b-0   !border-l-0"
        >
          {t("Favorites")}
        </p>
        <div
          // onClick={() => navigate("/account")}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className="flex gap-2 hover:bg-primary-color hover:text-white cursor-pointer duration-700 items-center !border-y-0 h-full px-10 border !border-b-0   !border-l-0"
        >
          {t("Services")}
          <img
            src={arrow}
            // className={`${
            //   openServices ? "rotate-180" : ""
            // } transition-all duration-1000 w-4`}
            className="w-3"
            alt="Arrow"
          />
          {hover && (
            <div className="absolute left-0 !z-50 w-full justify-around  transition-all duration-300 ease-in-out   top-16  flex">
              <div
                onMouseEnter={() => setHover(true)}
                className="flex justify-around w-full mx-20 mt-4 bg-white rounded-lg shadowbox"
              >
                {data?.services?.map((item, index) => (
                  <div
                    onClick={() => {
                      handleViewSubServices(item.id);
                      setServicesName(item.name);
                    }}
                    key={index}
                    className="flex flex-col items-center gap-2 py-4 text-black duration-1000  hover:!text-primary-color"
                  >
                    <img src={item.image} alt="mibo" className="w-20" />
                    <p className="">{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <p
          className="flex hover:bg-primary-color hover:text-white cursor-pointer duration-700 items-center !border-y-0 h-full px-10 border !border-b-0   !border-l-0"
          onClick={() => navigate("/account")}
        >
          {t("Account")}
        </p>
        <p
          className="cursor-pointer flex items-center h-full px-10 border !border-b-0   !border-l-0 !border-r-0"
          onClick={toggleLang}
        >
          {direction === "rtl" ? "En" : "De"}
        </p>
      </div>
    </div>
  );
};

export default UserSetting;
