import { _axios } from "interceptor/http-config";
import { enqueueSnackbar } from "notistack";
import { HttpRequestInterceptor } from "interceptor/http-request.interceptor";

export const _NotificationApi = {
  index: async ({ pageParam = 1 }) => {
    return _axios
      .get(`/user/notification?page=${pageParam}`, {
        headers: {
          ...HttpRequestInterceptor(),
        },
      })
      .then((res) => res.data)
      .catch((error) =>
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        })
      );
  },
};
