import { _AuthApi } from "api/auth";
import { useNavBarLogic } from "layout/hooks/useNavBarLogic";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export const useRegister = ({ setOpenSignUp }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("auth");
  const { handleSubmit } = useForm();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [termsOfUs, setOpenTerms] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [news_letter, setNews_letter] = useState(1 || 0);
  const [openPricavyDialog, setopenPricavyDialog] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const { setOpenSignIn } = useNavBarLogic();
  const handleClose = () => {
    setOpenSignUp(false);
  };
  const Login = () => {
    setOpenSignUp(false);
    setOpenSignIn(true);
  };
  const onSubmit = async () => {
    setLoading(true);

    try {
      await _AuthApi.register({
        email,
        phone,
        name,
        news_letter,
        password,
        confirm_password,
      });
      Swal.fire("Register Successful");

      navigate(`/otp/${phone}`);
      setOpenSignUp(false);
    } catch (error) {
      const errorResponse = error?.response?.data;
      if (errorResponse) {
        Swal.fire("An unexpected error occurred. Please try again later");
      }
      if (typeof errorResponse?.message === "object") {
        Object.values(errorResponse.message).forEach((errorMessages) => {
          errorMessages.forEach((errorMessage) => {
            Swal.fire(`${errorMessage}`);
          });
        });
      }
      if (typeof errorResponse?.message === "string") {
        Swal.fire(`${errorResponse?.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const hanldeShowPass = () => {
    setShowPass(!showPass);
  };
  const hanldeShowConfirmPass = () => {
    setShowConfirmPass(!showConfirmPass);
  };
  const [isAppleDevice] = useState(() =>
    /iPad|iPhone|iPod/.test(navigator.userAgent)
  );
  return {
    t,
    loading,
    handleClose,
    handleSubmit,
    onSubmit,
    googleLoading,
    setGoogleLoading,
    isAppleDevice,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    showPass,
    password,
    setPassword,
    hanldeShowPass,
    showConfirmPass,
    confirm_password,
    setconfirm_password,
    hanldeShowConfirmPass,
    news_letter,
    setNews_letter,
    Login,
    openPricavyDialog,
    setopenPricavyDialog,
    termsOfUs,
    setOpenTerms,
  };
};
