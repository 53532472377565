import React from "react";
import loader from "assets/images/error/notificationError.svg";
import { useTranslation } from "react-i18next";

const NotificationsError = () => {
  const { t } = useTranslation();
  return (
    <section className="px-5 mt-0 lg:px-16">
      <p className="mb-10 text-xl font-semibold lg:text-3xl">
        {t("Notifications")}
      </p>
      <div className="flex items-center justify-center w-full h-full bg-transparent ">
        <img src={loader} alt="" loading="lazy" />
      </div>
    </section>
  );
};

export default NotificationsError;
