import { useDebounce } from "hooks/useDebounce";
import { _axios } from "interceptor/http-config";
import { useMutation } from "react-query";
import { searchStore } from "store/searchStore";

const useSearch = () => {
  const setSearch = searchStore((state) => state.setSearch);
  const openSearchModal = searchStore((state) => state.openSearchModal);
  const setOpenSearchModal = searchStore((state) => state.setOpenSearchModal);
  const clearPage = searchStore((state) => state.clearPage);
  const setInputText = searchStore((state) => state.setInputText);
  const inputText = searchStore((state) => state.inputText);
  const setType = searchStore((state) => state.setType);
  const tab = searchStore((state) => state.tab);
  const setTab = searchStore((state) => state.setTab);
  const clearLists = searchStore((state) => state.clearLists);
  const setServicesList = searchStore((state) => state.setServicesList);
  const setSubServicesList = searchStore((state) => state.setSubServicesList);
  const setServiceProvidersList = searchStore(
    (state) => state.setServiceProvidersList
  );
  const { mutate, isLoading } = useMutation({
    mutationFn: async ({ value, page, type }) => {
      const formData = new FormData();
      formData.append("search", value);
      formData.append("page", page);
      formData.append("count", 10);
      if (type) formData.append("type", type);
      const response = await _axios.post("/user/search", formData);
      return response.data.data;
    },
    onSuccess: (response) => {
      setSearch(response);
      setType(tab);
      if (response.services) setServicesList(response.services.data);
      if (response.sub_services) setSubServicesList(response.sub_services.data);
      if (response.service_providers)
        setServiceProvidersList(response.service_providers.data);
    },
  });

  const reset = () => {
    clearPage();
    clearLists();
    setTab("services");
    setInputText("");
    setSearch(null);
  };

  const handleChange = useDebounce((e) => {
    handleSearch(e);
  }, 500);
  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value;
    clearPage();
    clearLists();
    setTab("services");
    setSearch(null);
    if (value === "") {
      return;
    }
    mutate({ value, page: 1, type: "" });
  };

  return {
    handleChange,
    isLoading,
    inputText,
    tab,
    mutate,
    setTab,
    setType,
    reset,
    setServiceProvidersList,
    setInputText,
    clearPage,
    setOpenSearchModal,
    openSearchModal,
    setSearch,
    setSubServicesList,
    setServicesList,
    clearLists,
  };
};

export default useSearch;
